import circle from 'assets/image/cirrcle.svg';
import circle2 from 'assets/image/cirrcle2.svg';
import { useAppDispatch } from 'hooks';
import { Notification } from 'interface/INotification';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import styles from './Notification.module.scss';

interface NotificationItemProps {
	className?: string;
	notification: Notification;
	setIsViewed: any
	isViewed: number[]
}

const NotificationItem = ({ className, notification, setIsViewed, isViewed }: NotificationItemProps) => {
	const dispatch = useAppDispatch();
	const { ref, inView } = useInView({
		threshold: 0,
	});

	useEffect(() => {
		if (inView && !notification.is_viewed) {
			!isViewed.includes(notification.id) && dispatch(setIsViewed(notification.id));
		}
	}, [inView]);

	function formatDateWithoutTime(dateString: string) {
		const date = new Date(dateString);

		const year = date.getFullYear();
		const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Месяцы в JavaScript начинаются с 0
		const day = date.getDate().toString().padStart(2, '0'); // Используем getDate() для получения дня месяца
		const hours = (date.getHours()).toString().padStart(2, '0');
		const minutes = (date.getMinutes()).toString().padStart(2, '0');
		const seconds = (date.getSeconds()).toString().padStart(2, '0');
		return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
	}

	const textLineBreak = (text: string) => {;
		const newText = text.split('\n').map((line, index) => (
		  <>
				{line}
				<br />
		  </>
		));
		return newText;
	};

	return (
		<div className={styles.notificationEL}>
			<img src={!notification.is_viewed ? circle : circle2} alt="" />
			<div>
				<p>{notification.header}</p>
				<p>{textLineBreak(notification.text)}</p>
				<p ref={ref}>{formatDateWithoutTime(notification.date)}</p>
			</div>
		</div>
	);
};

export default NotificationItem;
