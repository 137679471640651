import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { Info } from 'interface/IInfo';
import { IAllUsers } from 'interface/IUser';

export interface AuthState {
	allUsers: IAllUsers | null,
	isLoading: boolean,
	success: boolean
	error2: string | null
	error: boolean,
}

const initialState: AuthState = {
	allUsers: null,
	isLoading: false,
	success: false,
	error2: null,
	error: false,
};

const admin = createSlice({
	name: 'admin',
	initialState,
	reducers: {
		setAllUsers: (state, action: PayloadAction<IAllUsers>) => {
			state.allUsers = action.payload;
		},
		setLoadingAdmin:(state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload;
		},
		setSuccessAdmin:(state, action: PayloadAction<boolean>) => {
			state.success = action.payload;
		},
		setError2:(state, action: PayloadAction<string>) => {
			state.error2 = action.payload;
		},
		setError:(state, action: PayloadAction<boolean>) => {
			state.error = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { setAllUsers, setLoadingAdmin, setSuccessAdmin, setError2, setError } = admin.actions;

export default admin.reducer;