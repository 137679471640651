import { Box, Tab, Tabs, Typography } from '@mui/material';
import Cirrcle from 'assets/image/Cirrcle';
import empty from 'assets/image/EmptyNotification.svg';
import Header from 'components/Header/Header';
import { getNotifications, readNotifications, statEvent } from 'crud';
import { NotificationTypes } from 'enum';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { setIsViewed } from 'store/slices/Notifications';
import { cls } from 'utils/cls';

import styles from './Notification.module.scss';
import NotificationItem from './NotificationItem';

interface NotificationProps {
	className?: string;
}
interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function CustomTabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 1.5 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const Notification = ({ className }: NotificationProps) => {
	const isViewed = useAppSelector(state => state.notification.isViewed);
	const { ref, inView } = useInView({
		/* Optional options */
		threshold: 0,
		// initialInView: true,
	});
	const [value, setValue] = useState(0);
	const dispatch = useAppDispatch();
	const notifications = useAppSelector(
		(state) => state.notification.notifications,
	);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		if (newValue === 0) {
			dispatch(statEvent('notifications_important'));
		}
		else if (newValue === 1) {
			dispatch(statEvent('notifications_finance'));
		}
		else if (newValue === 2) {
			dispatch(statEvent('notifications_promotions'));
		}
		setValue(newValue);
	};

	useEffect(() => {
		dispatch(getNotifications());
	}, []);

	const sendDataRead = (e?: any) => {
		dispatch(readNotifications());
	};

	useEffect(() => {
		return () => {
			sendDataRead();
		};
	}, []);



	useEffect(() => {
		window.addEventListener('beforeunload', sendDataRead);
		return () => {
			window.removeEventListener('beforeunload', sendDataRead);
		};
	}, []);

	function hasUnreadNotifications(key: '1'|'2'|'3') {
		if (notifications) {
			return !!notifications.not_viewed[key].length;
		};
	}


	return (
		<div ref={ref} className={cls(styles.Notification, {}, [className || ''])}>
			<Header notification title="Notifications" />

			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<Tabs
					value={value}
					onChange={handleChange}
					aria-label="basic tabs example"
				>
					<Tab label="Important" icon={hasUnreadNotifications('1') ? <Cirrcle /> : ''} iconPosition="end" {...a11yProps(0)} />
					<Tab label="Finance" icon={hasUnreadNotifications('2') ? <Cirrcle /> : ''} iconPosition="end" {...a11yProps(1)} />
					<Tab label="Promotions" icon={hasUnreadNotifications('3') ? <Cirrcle /> : ''} iconPosition="end" {...a11yProps(2)} />
				</Tabs>
			</Box>
			<CustomTabPanel value={value} index={0}>
				<div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
					{notifications &&
          notifications?.not_viewed[NotificationTypes.IMPORTANT]?.length ? (
							notifications?.not_viewed[NotificationTypes.IMPORTANT].map(entry => ({
								...entry,
								notifications: [...entry.notifications].sort((a, b) => (new Date(b.date) as any) - (new Date(a.date) as any)),
							}))
								.sort((a, b) => (new Date(b.date) as any) - (new Date(a.date) as any))?.map((notification) => (
									<div>
										<p>{notification.date}</p>
										<div
											style={{ flexDirection: 'column', display: 'flex', gap: 15 }}
										>
											{notification?.notifications.map((el) => (
												<>
													<NotificationItem
														isViewed={isViewed}
														setIsViewed={setIsViewed}
														notification={el}
													/>
												</>
											))}
										</div>
									</div>
								))
						) : null}
					{notifications &&
          notifications[NotificationTypes.IMPORTANT]?.length ? (
							notifications[NotificationTypes.IMPORTANT].map(entry => ({
								...entry,
								notifications: [...entry.notifications].sort((a, b) => (new Date(b.date) as any) - (new Date(a.date) as any)),
							}))
								.sort((a, b) => (new Date(b.date) as any) - (new Date(a.date) as any))?.map((notification) => (
									<div>
										<p>{notification.date}</p>
										<div
											style={{ flexDirection: 'column', display: 'flex', gap: 15 }}
										>
											{notification?.notifications.map((el) => (
												<>
													<NotificationItem
														isViewed={isViewed}
														setIsViewed={setIsViewed}
														notification={el}
													/>
												</>
											))}
										</div>
									</div>
								))
						) : null}

					{
						notifications && (!notifications?.not_viewed[NotificationTypes.IMPORTANT].length && !notifications[NotificationTypes.IMPORTANT].length) ?
							<div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', padding: 100}}>
								<img src={empty} alt="" />
							</div>
							: null}
				</div>
			</CustomTabPanel>
			<CustomTabPanel value={value} index={1}>
				<div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
					{notifications &&
          notifications?.not_viewed[NotificationTypes.FINANCE]?.length ? (
							notifications.not_viewed[NotificationTypes.FINANCE].map(entry => ({
								...entry,
								notifications: [...entry.notifications].sort((a, b) => (new Date(b.date) as any) - (new Date(a.date) as any)),
							}))
								.sort((a, b) => (new Date(b.date) as any) - (new Date(a.date) as any))?.map((notification) => (
									<div>
										<p>{notification.date}</p>
										<div
											style={{ flexDirection: 'column', display: 'flex', gap: 15 }}
										>
											{notification?.notifications.map((el) => (
												<>
													<NotificationItem
														isViewed={isViewed}
														setIsViewed={setIsViewed}
														notification={el}
													/>
												</>
											))}
										</div>
									</div>
								))
						) : null}
					{notifications && notifications[NotificationTypes.FINANCE]?.length ? (
						notifications[NotificationTypes.FINANCE].map(entry => ({
							...entry,
							notifications: [...entry.notifications].sort((a, b) => (new Date(b.date) as any) - (new Date(a.date) as any)),
						}))
							.sort((a, b) => (new Date(b.date) as any) - (new Date(a.date) as any))?.map((notification) => (
								<div>
									<p>{notification.date}</p>
									<div
										style={{ flexDirection: 'column', display: 'flex', gap: 15 }}
									>
										{notification?.notifications.map((el) => (
											<>
												<NotificationItem
													isViewed={isViewed}
													setIsViewed={setIsViewed}
													notification={el}
												/>
											</>
										))}
									</div>
								</div>
							))
					) : null}

					{
						notifications && (!notifications?.not_viewed[NotificationTypes.FINANCE]?.length && !notifications[NotificationTypes.FINANCE].length) ?
							<div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', padding: 100}}>
								<img src={empty} alt="" />
							</div>
							: null}
				</div>
			</CustomTabPanel>
			<CustomTabPanel value={value} index={2}>
				<div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
					{notifications &&
          notifications?.not_viewed[NotificationTypes.PROMOTIONS]?.length ? (
							notifications.not_viewed[NotificationTypes.PROMOTIONS].map(entry => ({
								...entry,
								notifications: [...entry.notifications].sort((a, b) => (new Date(b.date) as any) - (new Date(a.date) as any)),
							}))
								.sort((a, b) => (new Date(b.date) as any) - (new Date(a.date) as any))?.map((notification) => (
									<div>
										<p>{notification.date}</p>
										<div
											style={{ flexDirection: 'column', display: 'flex', gap: 15 }}
										>
											{notification?.notifications.map((el) => (
												<>
													<NotificationItem
														isViewed={isViewed}
														setIsViewed={setIsViewed}
														notification={el}
													/>
												</>
											))}
										</div>
									</div>
								))
						) : null}
					{notifications &&
          notifications[NotificationTypes.PROMOTIONS]?.length ? (
							notifications[NotificationTypes.PROMOTIONS].map(entry => ({
								...entry,
								notifications: [...entry.notifications].sort((a, b) => (new Date(b.date) as any) - (new Date(a.date) as any)),
							}))
								.sort((a, b) => (new Date(b.date) as any) - (new Date(a.date) as any))?.map((notification) => (
									<div>
										<p>{notification.date}</p>
										<div
											style={{ flexDirection: 'column', display: 'flex', gap: 15 }}
										>
											{notification?.notifications.map((el) => (
												<>
													<NotificationItem
														isViewed={isViewed}
														setIsViewed={setIsViewed}
														notification={el}
													/>
												</>
											))}
										</div>
									</div>
								))
						): null}
					{
						notifications && (!notifications?.not_viewed[NotificationTypes.PROMOTIONS]?.length && !notifications[NotificationTypes.PROMOTIONS]?.length) ?
							<div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', padding: 100}}>
								<img src={empty} alt="" />
							</div>
							: null}
				</div>
			</CustomTabPanel>
		</div>
	);
};

export default Notification;
