import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CachedIcon from '@mui/icons-material/Cached';
import { Box, Modal } from '@mui/material';
import agreement from 'assets/image/agreement.svg';
import pay from 'assets/image/pay.svg';
import profile from 'assets/image/profile.svg';
import statistics from 'assets/image/statistics.svg';
import Header from 'components/Header/Header';
import { getStatisticsPublic, getTopInvestors, statEvent } from 'crud';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { cls } from 'utils/cls';
import { customToFixed } from 'utils/customToFixed';

import styles from './Setting.module.scss';

interface SettingProps {
	className?: string;
}

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	outline: 'none',
	width: '90%',
	bgcolor: '#191B1E',
	borderRadius: '16px',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	p: 1.6,
};

const Setting = ({ className }: SettingProps) => {
	const navigate = useNavigate();
	const [topInvestors, setTopInvestors] = useState(false);
	const [statisticsModal, setStatisticsModal] = useState(false);
	const {user} = useAppSelector(state => state.auth);
	const {statisticsPublic, topInvestors: topInvestorsData} = useAppSelector(state => state.statistics);
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(getStatisticsPublic());
		dispatch(getTopInvestors());
	}, []);

	const clearCache = () => {
		dispatch(statEvent('settings_clearthecache'));
		for (let key in localStorage) {
			if (localStorage.hasOwnProperty(key) && key !== 'start') {
			  localStorage.removeItem(key);
			}
		}
		navigate('/');
		window.location.reload();
	};

	const topMedals = (index: number) => {
		switch (index) {
			case 1: return '🥇';
			case 2: return '🥈';
			case 3: return '🥉';
			case 4: return '🏅';
			case 5: return '🎖';
		}
	};
	return (
		<div className={cls(styles.Setting, {}, [className || ''])}>
			<Header title="Account settings" />
			<div style={{ padding: '10px 16px' }}>
				<p style={{ fontSize: 22, fontWeight: 700, marginBottom: 30 }}>
					{user?.user?.username ? `@${user?.user?.username}` : user?.user?.first_name || '-'}
				</p>

				<div className={styles.menu}>
					<div
						onClick={() => {
							dispatch(statEvent('settings_chat'));
							window.open('https://t.me/cryptobantergroup');
						}}
						className={styles.menuEl}
					>
						<div>
							<img src={profile} alt="" />
							<p>Chat</p>
						</div>
						<ArrowForwardIosIcon sx={{ width: 20, color: '#4C4AEF' }} />
					</div>
					<div onClick={() => {
						dispatch(statEvent('settings_topinvestors'));
						setTopInvestors(true);
					}}
					className={styles.menuEl}>
						<div>
							<img src={statistics} alt="" />
							<p>Top Investors</p>
						</div>
						<ArrowForwardIosIcon sx={{ width: 20, color: '#4C4AEF' }} />
					</div>
					<div onClick={() => {
						dispatch(statEvent('settings_statistics'));
						setStatisticsModal(true);
					}}
					className={styles.menuEl}>
						<div>
							<img src={statistics} alt="" />
							<p>Statistics</p>
						</div>
						<ArrowForwardIosIcon sx={{ width: 20, color: '#4C4AEF' }} />
					</div>
					<div onClick={() => {
						dispatch(statEvent('settings_payouts'));
						navigate('/payouts');
					}}
					className={styles.menuEl}>
						<div>
							<img src={pay} alt="" />
							<p>Payouts</p>
						</div>
						<ArrowForwardIosIcon sx={{ width: 20, color: '#4C4AEF' }} />
					</div>
					<div onClick={() => {
						dispatch(statEvent('settings_agreement'));
						window.open('https://telegra.ph/Crypto-Investor-Policy-05-03');
					}}
					className={styles.menuEl}>
						<div>
							<img src={agreement} alt="" />
							<p>Agreement</p>
						</div>
						<ArrowForwardIosIcon sx={{ width: 20, color: '#4C4AEF' }} />
					</div>
					<div onClick={clearCache} className={styles.menuEl}>
						<div>
							<CachedIcon sx={{width: 20, height: 24}} />
							<p>Clear the cache</p>
						</div>
					</div>
				</div>
			</div>

			<Modal open={topInvestors} onClose={() => {
				dispatch(statEvent('settings_topinvestors_close'));
				setTopInvestors(false);
			}}>
				<Box sx={style}>
					<p style={{ fontWeight: 600, marginBottom: 10 }}>Top investors in a Cryptobank</p>
					<p style={{fontSize: 12, color: '#B4B4B4', display: 'flex', flexDirection: 'column', gap: 5}}>
						{
							topInvestorsData?.map((el, i) => (
								<span>
									{topMedals(i + 1)} {i+1}. {el.first_name}({el.username ? `@${el.username}` : '-'})
									[ Deposit: {customToFixed(el.crypto_bank, 2)} USD ] [Earned] - {customToFixed(el.cb_earned, 2)} USD<br />
								</span>
							))
						}
					</p>
					<button
						onClick={() => {
							dispatch(statEvent('settings_topinvestors_close'));
							setTopInvestors(false);
						}}
						style={{width: '100%', backgroundColor: '#4C4AEF', padding: 16, color: '#EDEDFD', fontSize: 16, border: 'none', borderRadius: 12, marginTop: 15}}>
						<p>Close</p></button>
				</Box>
			</Modal>

			<Modal open={statisticsModal} onClose={() => {
				dispatch(statEvent('settings_statistics_close'));
				setStatisticsModal(false);
			}}>
				<Box sx={{...style, alignItems: 'start'}}>
					<p style={{ fontWeight: 600, marginBottom: 10, textAlign: 'center' }}>You can see statistics on all users Crypto Investor</p>
					<p style={{fontSize: 14, color: '#B4B4B4'}}>
						Days working: {statisticsPublic?.days_working}<br />
						Total investors: {statisticsPublic?.total_investors}<br />
						New in 24 hours: {statisticsPublic?.new_in_24_hours}
					</p>
					<button
						onClick={() => {
							dispatch(statEvent('settings_statistics_close'));
							setStatisticsModal(false);
						}}
						style={{width: '100%', backgroundColor: '#4C4AEF', padding: 16, color: '#EDEDFD', fontSize: 16, border: 'none', borderRadius: 12, marginTop: 15}}>
						<p>Close</p></button>
				</Box>
			</Modal>
		</div>
	);
};

export default Setting;
