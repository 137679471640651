import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import Header from 'components/Header/Header';
import { createTransaction, statEvent } from 'crud';
import { BankTypes, BankTypesReverse, CurrencyTypes, PaymentMethodTypes, TransactionTypes } from 'enum';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CreateTransaction, editCreateTransactions } from 'store/slices/Transactions';
import { cls } from 'utils/cls';
import { customToFixed } from 'utils/customToFixed';

import styles from './Transfer.module.scss';

interface TransferProps {
	className?: string
}

const Transfer = ({ className }: TransferProps) => {
	const user = useAppSelector(state => state.auth.user?.user);
	const createTransactionData = useAppSelector(state => state.transactions.createTransaction);
	const [from, setFrom] = useState(BankTypesReverse[BankTypes.CRYPTOBANK]);
	const [inp, setInp] = useState(from === BankTypesReverse[BankTypes.CRYPTOBANK] ? user?.crypto_bank : user?.crypto_pump);
	const [to, setTo] = useState(BankTypes.CRYPTOPUMP);
	const dispatch = useAppDispatch();

	const handleChangeFrom = (event: SelectChangeEvent) => {
		setFrom(event.target.value as string);
		dispatch(statEvent(`transfer_set${event.target.value.toLocaleLowerCase()}`));
		if (event.target.value === BankTypesReverse[BankTypes.CRYPTOBANK]) {
			setTo(BankTypes.CRYPTOPUMP);
			dispatch(editCreateTransactions(
				{
					type: TransactionTypes.TRANSFER,
					bank: to,
					currency: CurrencyTypes.OTHER,
					amount: Number(inp),
					extra: null,
					payment_method: PaymentMethodTypes.VISA_MASTERCARD,
				},
			));
		}
		else {
			setTo(BankTypes.CRYPTOBANK);
		}
	};

	useEffect(() => {
		if (from) {
			setInp(from === BankTypesReverse[BankTypes.CRYPTOBANK] ? user?.crypto_bank : user?.crypto_pump);
		}
	}, [from]);

	useEffect(() => {
		dispatch(editCreateTransactions(
			{
				type: TransactionTypes.TRANSFER,
				bank: to,
				currency: CurrencyTypes.OTHER,
				amount: Number(inp),
				extra: null,
				payment_method: PaymentMethodTypes.VISA_MASTERCARD,
			},
		));
	}, [to, inp, from]);

	return (
		<div className={cls(styles.Transfer, {}, [className || ''])}>
			<Header title="Transfer" />
			<div style={{padding: 14}}>
				<div style={{padding: 24}}>
					<p style={{color: '#C8C7FA', fontSize: 14}}>Total Balance</p>
					<p style={{fontSize: 30, fontWeight: 600}}>${customToFixed((user?.crypto_bank as number) + (user?.crypto_pump as number), 2)}</p>
				</div>
				<div style={{padding: 24,
					background: 'linear-gradient(180.00deg, rgb(189, 188, 255),rgb(210, 209, 255) 28.646%,rgb(226, 225, 255) 100%)',
					borderRadius: 16,
					display: 'flex',
					flexDirection: 'column',
					gap: 10,
				}}>
					<div className={styles.balance}>
						<p>Cryptobank balance</p>
						<p>${customToFixed(user?.crypto_bank || 0, 2) || 0}</p>
					</div>
					<div className={styles.balance}>
						<p>CryptoPUMP balance</p>
						<p>${customToFixed(user?.crypto_pump || 0, 2) || 0}</p>
					</div>
				</div>
				<div style={{marginTop: 15}}>
					<p style={{fontSize: 17}}>The amount to withdraw</p>
					<div className={styles.transferWhere}>
						<p>From</p>
						<div className={styles.body_amount}>
							<p>$</p>
							<input
								disabled
								value={inp}
								onKeyDown={(event) => {
									if (event.key === '-') {
										event.preventDefault();
									}
								}}
								min="0"
								type="number" />
							<FormControl sx={{paddingRight: 4}}>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={from}
									onChange={handleChangeFrom}
									sx={{color: '#D8D8D8'}}
									onOpen={() => {
										dispatch(statEvent('transfer_settype'));
									}}
								>
									<MenuItem value={BankTypesReverse[BankTypes.CRYPTOBANK]}>cryptobank</MenuItem>
									<MenuItem value={BankTypesReverse[BankTypes.CRYPTOPUMP]}>cryptoPUMP</MenuItem>
								</Select>
							</FormControl>
						</div>
					</div>
					<button
						onClick={() => {
							dispatch(createTransaction(createTransactionData as CreateTransaction));
							if (to === BankTypes.CRYPTOBANK) {
								dispatch(statEvent(`transfer_action_fromcryptopump{${inp}}`));
							}
							else {
								dispatch(statEvent(`transfer_action_fromcryptobank{${inp}}`));
							}
						}}
						className={styles.btn}>
						<p>Transfer</p>
					</button>
				</div>
			</div>
		</div>
	);
};

export default Transfer;
