import { Button } from '@mui/material';
import telgeram from 'assets/image/telegram.jpeg';
import animationData from 'assets/lotties/animation.json';
import Lottie from 'react-lottie';
import { cls } from 'utils/cls';

import styles from './ErrorPageForPc.module.scss';

interface ErrorPageForPcProps {
	className?: string
}

const defaultOptions = {
	loop: true,
	autoplay: true,
	animationData: animationData,
	rendererSettings: {
	  preserveAspectRatio: 'xMidYMid slice',
	},
};


const ErrorPageForPc = ({ className }: ErrorPageForPcProps) => {
	const openTelegram = () => {
		window.open('https://t.me/Cryptoinvestor_onlineBot', '_self');
	};
	const openDownloadTelegram = () => {
		window.open('https://telegram.org/', '_self');
	};
	return (
		<div className={cls(styles.ErrorPageForPc, {}, [className || ''])}>
			<div className={styles.header}>
				<img style={{width: 120}} src={telgeram} alt="" />
			</div>
			<div className={styles.body}>
				<Lottie
					options={defaultOptions}
					height={250}
					width={400}
				/>
				<div style={{width: '30%'}}>
					<p className={styles.title}>Crypto Investor</p>
					<p className={styles.description}>Crypto Investor is a platform for investing, analyzing crypto market and crypto games.</p>
					<div style={{display: 'flex', gap: 10, marginTop: 20}}>
						<Button onClick={openTelegram}
							sx={{width: '50%', fontSize: '1rem', fontWeight: 500, textTransform: 'none'}} variant="contained">Open Telegram</Button>
						<Button
							onClick={openDownloadTelegram}
							sx={{width: '50%', fontSize: '1rem', fontWeight: 500, textTransform: 'none', bgcolor: '#6c757d !important'}}
							variant="contained">I don't have Telegram</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ErrorPageForPc;
