export const setItem = (key: string, value: string) => {
	localStorage.setItem(key, value);
};

export const getItem = (key: string) => {
	return localStorage.getItem(key);
};

export const removeItem = (key: string) => {
	return localStorage.getItem(key);
};

export const setItemSession = (key: string, value: string) => {
	sessionStorage.setItem(key, value);
};

export const getItemSession = (key: string) => {
	return sessionStorage.getItem(key);
};

export const removeItemSession = (key: string) => {
	return sessionStorage.getItem(key);
};
