import { ReactNode } from 'react';
import { cls } from 'utils/cls';

import styles from './Container.module.scss';

interface ContainerProps {
	className?: string,
	children: ReactNode
}

const Container = ({ className, children }: ContainerProps) => {
	return (
		<div className={cls(styles.Container, {}, [className || ''])}>
			{children}
		</div>
	);
};

export default Container;
