import { type Container, type ISourceOptions, MoveDirection, OutMode } from '@tsparticles/engine';
import Particles, { initParticlesEngine } from '@tsparticles/react';
// import { loadAll } from "@/tsparticles/all"; // if you are going to use `loadAll`, install the "@tsparticles/all" package too.
// import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
import { loadSlim } from '@tsparticles/slim'; // if you are going to use `loadSlim`, install the "@tsparticles/slim" package too.
import { useEffect, useMemo, useState } from 'react';
// import { loadBasic } from "@tsparticles/basic"; // if you are going to use `loadBasic`, install the "@tsparticles/basic" package too.

export const ParticlesComp = () => {
	const [init, setInit] = useState(false);

	// this should be run only once per application lifetime
	useEffect(() => {
		initParticlesEngine(async (engine) => {
			// you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
			// this loads the tsparticles package bundle, it's the easiest method for getting everything ready
			// starting from v2 you can add only the features you need reducing the bundle size
			// await loadAll(engine);
			// await loadFull(engine);
			await loadSlim(engine);
			// await loadBasic(engine);
		}).then(() => {
			setInit(true);
		});

	}, []);

	const particlesLoaded = async (container?: Container): Promise<void> => {
		console.log(container);
	};

	const options: ISourceOptions = useMemo(
		() => ({
			background: {
				image: 'linear-gradient(180deg, rgba(53,76,252,1) 0%, rgba(169,188,255,1) 74%, rgba(255,255,255,1) 100%)',
			},
			style: {
				width: `${window.innerWidth - 20}px`,
				height: '220px',
				marginTop: '5px',
				margin: '6px',
				boxShadow: '20px 20px #000',
				position: 'absolute',
			},
			fpsLimit: 120,
			particles: {
				color: {
					value: '#fff',
				},
				links: {
					color: '#fff',
					distance: 150,
					enable: true,
					opacity: 0.5,
					width: 1,
				},
				move: {
					direction: MoveDirection.none,
					enable: true,
					outModes: {
						default: OutMode.out,
					},
					random: false,
					speed: 1.5,
					straight: false,
				},
				number: {
					density: {
						enable: true,
					},
					value: 800,
				},
				opacity: {
					value: 0.5,
				},
				size: {
					value: { min: 2, max: 3 },
				},
			},
			detectRetina: true,
		}),
		[],
	);

	if (init) {
		return <Particles id="tsparticles" particlesLoaded={particlesLoaded} options={options} />;
	}

	return <></>;
};