import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { IPayouts } from 'interface/IPayouts';

export interface AuthState {
	payouts: IPayouts[] | null,
	isLoading: boolean,
}

const initialState: AuthState = {
	payouts: null,
	isLoading: false,
};

const payouts = createSlice({
	name: 'info',
	initialState,
	reducers: {
		setPayouts: (state, action: PayloadAction<IPayouts[]>) => {
			state.payouts = action.payload;
		},
		setLoadingPayouts:(state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { setPayouts, setLoadingPayouts} = payouts.actions;

export default payouts.reducer;