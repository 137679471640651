import CloseIcon from '@mui/icons-material/Close';
import { Alert, Box, FormControl, MenuItem, Modal, Pagination,
	PaginationItem, Paper, Select, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography }
	from '@mui/material';
import Header from 'components/Header/Header';
import { changeInfo, changeUser, changeUserAll, getStatisticsAdmin, getStatisticsPublic,
	getUsers, giveBonus, searchUsers, sendNotification, sendTelegramNotification } from 'crud';
import { BankTypes, NotificationTypes } from 'enum';
import { useAppDispatch, useAppSelector } from 'hooks';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { setSuccessAdmin } from 'store/slices/Admin';
import { cls } from 'utils/cls';
import { customToFixed } from 'utils/customToFixed';

import styles from './Admin.module.scss';
import AdminElement from './AdminElement/AdminElement';

interface AdminProps {
	className?: string
}

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '90%',
	outline: 'none',
	background: '#0C0C0C',
	padding: '15px 10px 20px 10px',
	borderRadius: 5,
	overflow: 'scroll',
	height: '90%',
};


const Admin = ({ className }: AdminProps) => {
	const ref = useRef<any>(null);
	const token = useAppSelector(state => state.auth.user?.token);
	const [modalUsers, setModalUsers] = useState(false);
	const isAdmin = useAppSelector(state => state.auth.user?.user.groups)?.includes('admins');
	const allUsers = useAppSelector(state => state.admin.allUsers);
	const statistics = useAppSelector(state => state.statistics.statisticsAdmin);
	const isSuccess = useAppSelector(state => state.admin.success);
	const [copied, setCopied] = useState(false);
	const [searchInp, setSearchInp] = useState('');
	const info = useAppSelector(state => state.info.info);
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const [data, setData] = useState({
		balance: {
			value: null,
			bank: BankTypes.CRYPTOBANK,
			userId: '',
		},
		bonus: {
			value: null,
			bank: BankTypes.CRYPTOBANK,
			userId: '',
		},
		percentServiceAll: {
			value: null,
			bank: BankTypes.CRYPTOBANK,
		},
		percentServiceUser: {
			value: null,
			bank: BankTypes.CRYPTOBANK,
			userId: '',
		},
		news: {
			header: null,
			body: null,
		},
		sendNotification: {
			header: null,
			body: null,
			type: 0,
			userId: '',
		},
		sendNotificationTelegram: {
			value: null,
			type: 0,
			userId: '',
		},
		statistics: {
			value: null,
		},
		coin: {
			value: null,
		},
		energy: {
			value: null,
		},
	});

	useEffect(() => {
		if (token && !isAdmin) {
			navigate('/');
		}
	}, [token, token]);

	const handleChangeFrom = (key: string, key2: string, value: number | string) => {
		setData((prev: any) => ({ ...prev, [key]: { ...prev[key], [key2]: value } }));
	};

	useEffect(() => {
		if (info) {
			setData((prev: any) => ({...prev,
				coin: {
					value: info.coin_rate,
				},
				statistics: {
					value: `${info.fake_users_number_minimum}-${info.fake_users_number_maximum}`,
				},
				energy: {
					value: info.max_energy,
				},
				news: {
					header: info.news_header,
					body: info.news_description,
				},
			}));
		}
	}, [info]);

	useEffect(() => {
		if (copied) {
			setTimeout(() => {
				setCopied(false);
			}, 2000);
		}
	}, [copied]);

	const scroll = () => {
		if (ref.current) {
			const { scrollTop } = ref.current;
			if (scrollTop > 0) {
			  window.scrollTo({
					top: window.pageYOffset + .5,
					behavior: 'auto',
			  });
			}
		  }
	  };

	useEffect(() => {
		if (info) {
			if (BankTypes.CRYPTOBANK === data.percentServiceAll.bank) {
				setData((prev: any) => ({...prev,
					percentServiceAll: {
						...data.percentServiceAll,
						value: info.cb_percent * 100,
					},
				}));
			}
			else if (BankTypes.CRYPTOPUMP === data.percentServiceAll.bank) {
				setData((prev: any) => ({...prev,
					percentServiceAll: {
						...data.percentServiceAll,
						value: `${info.cp_percent_minimum * 100}-${info.cp_percent_maximum * 100}`,
					},
				}));
			}
			else {
				setData((prev: any) => ({...prev,
					percentServiceAll: {
						...data.percentServiceAll,
						value: info.referral_interest * 100,
					},
				}));
			}
		}
	}, [info, data.percentServiceAll.bank]);


	const handleChangeBalance = () => {
		const bank = BankTypes.CRYPTOBANK === data.balance.bank ? 'crypto_bank' : 'crypto_pump';
		if (data.balance.value && data.balance.userId) {
			dispatch(changeUser({
				userId: data.balance.userId,
				[bank]: Number(data.balance.value),
			}));
		}
	};

	const handleGiveBonus = () => {
		if (data.bonus.value && data.bonus.userId) {
			dispatch(giveBonus({
				userId: data.bonus.userId,
				value: Number(data.bonus.value),
				bank: data.bonus.bank,
			}));
		}
	};
	;

	const handleChangePercentAll = () => {
		const isBank = data.percentServiceAll.bank === BankTypes.CRYPTOBANK;
		const isPump = data.percentServiceAll.bank === BankTypes.CRYPTOPUMP;
		const isFriends = data.percentServiceAll.bank === BankTypes.FRIEND;
		// @ts-ignore
		const value = data.percentServiceAll?.value?.split(/-+/);

		if (isPump && value?.length > 1) {
			const sendData = {
				cp_percent_maximum: Number(value[1]) / 100,
				cp_percent_minimum: Number(value[0]) / 100,
			};
			dispatch(changeInfo(sendData));
			dispatch(changeUserAll(sendData));
			return;
		}
		else if (isBank && value?.length === 1) {
			const sendData = {
				cb_percent: Number(data.percentServiceAll?.value) / 100,
			};
			dispatch(changeInfo(sendData));
			dispatch(changeUserAll(sendData));
			return;
		}
		else if (isFriends && value?.length === 1) {
			const sendData = {
				referral_interest: Number(data.percentServiceAll?.value) / 100,
			};
			dispatch(changeInfo(sendData));
			dispatch(changeUserAll(sendData));
			return;
		}
	};

	const handleChangePercentUser = () => {
		const isBank = data.percentServiceUser.bank === BankTypes.CRYPTOBANK;
		const isPump = data.percentServiceUser.bank === BankTypes.CRYPTOPUMP;
		const isFriends = data.percentServiceUser.bank === BankTypes.FRIEND;
		// @ts-ignore
		const value = data.percentServiceUser?.value?.split(/-+/);

		if (isPump && value?.length > 1 && data.percentServiceUser.userId) {
			const sendData = {
				userId: data.percentServiceUser.userId,
				cp_percent_maximum: Number(value[1]) / 100,
				cp_percent_minimum: Number(value[0]) / 100,
			};
			dispatch(changeUser(sendData));
			return;
		}
		else if (isBank && value?.length === 1 && data.percentServiceUser.userId) {
			const sendData = {
				userId: data.percentServiceUser.userId,
				cb_percent: Number(data.percentServiceUser?.value) / 100,
			};
			dispatch(changeUser(sendData));
			return;
		}
		else if (isFriends && value?.length === 1 && data.percentServiceUser.userId) {
			const sendData = {
				userId: data.percentServiceUser.userId,
				referral_interest: Number(data.percentServiceUser?.value) / 100,
			};
			dispatch(changeUser(sendData));
			return;
		}
	};

	const handleChangeNews = () => {
		if (data.news.body && data.news.header) {
			dispatch(changeInfo({
				news_header: data.news.header,
				news_description: data.news.body,
			}));
		}
	};

	const handleSendNotificationImportant = () => {
		if (data.sendNotification.body && data.sendNotification.header && (data.sendNotification.type ? data.sendNotification.userId : true)) {
			dispatch(sendNotification({
				user: data.sendNotification.userId || '__all__',
				type: NotificationTypes.IMPORTANT,
				header: data.sendNotification.header,
				text: data.sendNotification.body,
			}));
		}
	};

	const handleGetStatistics = () => {
		dispatch(getStatisticsAdmin());
	};

	const handleChangeStatistics = () => {
		// @ts-ignore
		const value = data.statistics?.value?.split(/-+/);

		if (value && value.length > 1) {
			dispatch(changeInfo({
				fake_users_number_minimum: Number(value[0]),
				fake_users_number_maximum: Number(value[1]),
			}));
		}
	};

	const changeCoin = () => {
		if (data.coin.value) {
			dispatch(changeInfo({
				coin_rate: Number(data.coin.value),
			}));
		}
	};

	const handleSendTelegramNotification = () => {
		if (data.sendNotificationTelegram.value && (data.sendNotificationTelegram.type ? data.sendNotificationTelegram.userId : true)) {
			dispatch(sendTelegramNotification({
				user: Number(data.sendNotificationTelegram.userId) || '__all__',
				text: data.sendNotificationTelegram.value,
			}));
		}
	};

	const changeMaxEnergy = () => {
		if (data.energy.value) {
			dispatch(changeInfo({
				max_energy: Number(data.energy.value),
			}));
		}
	};

	const handleGetUsers = () => {
		setModalUsers(true);
		dispatch(getUsers({
			page: 1,
		}));
	};

	const copyText = (text: string) => {
		if (text) {
			navigator.clipboard.writeText(text).then((_) => {
				setCopied(true);
				dispatch(setSuccessAdmin(true));
			});
		}
	};

	return (
		<div className={cls(styles.Admin, {}, [className || ''])}>
			<Header title="Admin menu"/>
			<Snackbar open={isSuccess} autoHideDuration={3000} onClose={() => dispatch(setSuccessAdmin(false))}>
				<Alert
					onClose={() => dispatch(setSuccessAdmin(false))}
					severity="success"
					variant="filled"
					sx={{ width: '100%' }}
				>
					Success
				</Alert>
			</Snackbar>

			<div className={styles.form}>
				<button onClick={handleGetUsers}
					style={{background: '#4C4AEF', padding: '8px 16px', color: '#EDEDFD', border: 'none', marginTop: 10, borderRadius: 5}}>
					<p>Get users</p>
				</button>
				<AdminElement
					title="Set balance"
					description="Sets the balance of a specific service"
					btnText="Set balance"
					icon="$"
					data={data}
					handleChangeFrom={handleChangeFrom}
					type="balance"
					handleSave={handleChangeBalance}
				/>

				<AdminElement
					title="Give a bonus"
					description="Gives bonus to user and sends notification about bonus"
					btnText="Send bonus"
					icon="$"
					data={data}
					handleChangeFrom={handleChangeFrom}
					handleSave={handleGiveBonus}
					type="bonus"
				/>

				<AdminElement
					friends
					all
					title="Set the percentage of service income ALL USERS"
					description="Percentage change in ALL USERS"
					btnText="Set % ALL USERS"
					icon="%"
					data={data}
					handleChangeFrom={handleChangeFrom}
					type="percentServiceAll"
					handleSave={handleChangePercentAll}
				/>

				<AdminElement
					friends
					title="Set the percentage of service income individual"
					description="Changes the individual bonus percentage of service income and sends a notification to the user"
					btnText="Set USER %"
					icon="%"
					data={data}
					handleChangeFrom={handleChangeFrom}
					type="percentServiceUser"
					handleSave={handleChangePercentUser}
				/>


				<div>
					<div>
						<p style={{marginBottom: 5, color: '#B4B4B4'}}>Write new news</p>
						<div className={styles.body_amount}>
							<p>Header</p>
							<input
								value={data.news.header || ''}
								onChange={(e) => handleChangeFrom('news', 'header', e.target.value)}
								type="text"
							/>
						</div>
						<p style={{color: '#B4B4B4', fontSize: 14}}>Sets the headline in the news on the main page of the application</p>
					</div>

					<div style={{marginTop: 20}}>
						<p style={{marginBottom: 5, color: '#B4B4B4'}}>Write the text of the news</p>
						<div style={{alignItems: 'start'}} className={styles.body_amount}>
							<p>Text</p>
							<textarea
								style={{minHeight: 50, verticalAlign: 'top'}}
								value={data.news.body || ''}
								onChange={(e) => handleChangeFrom('news', 'body', e.target.value)}
							/>
						</div>

						<div style={{display: 'flex', flexDirection: 'column', alignItems: 'end'}}>
							<button onClick={handleChangeNews}
								style={{background: '#4C4AEF', padding: '8px 16px', color: '#EDEDFD', border: 'none', marginTop: 10, borderRadius: 5}}>
								<p>Publish news</p>
							</button>
						</div>
					</div>
				</div>



				<div>
					<div>
						<p style={{marginBottom: 5, color: '#B4B4B4'}}>Send Important notification</p>
						<div className={styles.body_amount}>
							<p>Header</p>
							<input
								value={data.sendNotification.header || ''}
								onChange={(e) => handleChangeFrom('sendNotification', 'header', e.target.value)}
								type="text"
							/>
						</div>
						<p style={{color: '#B4B4B4', fontSize: 14}}>Send Important notification in telegram and notification menu</p>

					</div>

					<div style={{marginTop: 15}}>
						<p style={{marginBottom: 5, color: '#B4B4B4'}}>Write the text of the news</p>
						<div style={{alignItems: 'start'}} className={styles.body_amount}>
							<p>Text</p>
							<textarea
								style={{minHeight: 50, verticalAlign: 'top'}}
								value={data.sendNotification.body || ''}
								onChange={(e) => handleChangeFrom('sendNotification', 'body', e.target.value)}
							/>
							<FormControl sx={{paddingRight: data.sendNotification.type ? 3 : 1, mt: 2}}>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={data.sendNotification.type}
									// @ts-ignore
									onChange={(e: ChangeEvent<HTMLInputElement>) => {
										handleChangeFrom('sendNotification', 'type', e.target.value);
									}}
									sx={{color: '#D8D8D8'}}
								>
									<MenuItem value={0}>All</MenuItem>
									<MenuItem value={1}>User ID</MenuItem>
								</Select>
							</FormControl>
						</div>
						{data.sendNotification.type ?
							<div style={{marginTop: 10}}>
								<p style={{marginBottom: 5, color: '#B4B4B4', textAlign: 'left', width: '100%'}}>ID user</p>
								<div className={styles.body_amount}>
									<p>@</p>
									<input
										value={data.sendNotification.userId || ''}
										onChange={(e) => handleChangeFrom('sendNotification', 'userId', e.target.value)}
										type="text"
									/>
								</div>
							</div>
							: ''
						}
						<div style={{display: 'flex', flexDirection: 'column', alignItems: 'end'}}>
							<button onClick={handleSendNotificationImportant}
								style={{background: '#4C4AEF', padding: '8px 16px', color: '#EDEDFD', border: 'none', marginTop: 10, borderRadius: 5}}>
								<p>Send notification</p>
							</button>
						</div>
					</div>
				</div>


				<div>
					<p style={{marginBottom: 5, color: '#B4B4B4'}}>Statistics</p>
					<div style={{display: 'flex', flexDirection: 'column', alignItems: 'end'}}>
						<button onClick={handleGetStatistics}
							style={{background: '#4C4AEF', padding: '8px 16px', color: '#EDEDFD', border: 'none', marginTop: 10, borderRadius: 5, marginBottom: 10}}>
							<p>Get statistics</p>
						</button>
					</div>
					{statistics && (
						<div>
							<p>Real users: {statistics.real}</p>
							<p>New users 24 hours: {statistics.real_24}</p>
							<p>Total users in the bot(FAKE): {statistics.fake}</p>
							<p>New users in the last 24h (FAKE): {statistics.fake_24}</p>
						</div>
					)}

					<p style={{color: '#B4B4B4', fontSize: 14, marginTop: 5}}>to change new fake users in last 24h </p>

					<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 50}}>
						<div
							style={{width: '50%', marginTop: 20}}
							className={styles.body_amount}>
							<p>+</p>
							<input
								value={data.statistics.value || ''}
								onKeyDown={(event) => {
									// Разрешаем только цифры и только один знак "-"
									if (
										event.key !== 'Backspace' &&
										(event.key !== '-') &&
										!/^\d$/.test(event.key)
									) {
										event.preventDefault();
									}
								}}
								onChange={(e) => handleChangeFrom('statistics', 'value', e.target.value)}
								type="text"
							/>
						</div>
						<button onClick={handleChangeStatistics}
							style={{background: '#4C4AEF', padding: '8px 16px', color: '#EDEDFD', border: 'none', marginTop: 10, borderRadius: 5, height: 50}}>
							<p>Change new users</p>
						</button>
					</div>


				</div>


				<AdminElement
					friends
					all
					title="Set convert rate coin in Mining Coin ALL USERS"
					description=""
					btnText="Set rate"
					icon="$"
					data={data}
					handleChangeFrom={handleChangeFrom}
					type="coin"
					select={false}
					handleSave={changeCoin}
				/>


				<div>
					<div>
						<p style={{marginBottom: 5, color: '#B4B4B4'}}>Send Telegram bot notification</p>
						<div style={{alignItems: 'center'}} className={styles.body_amount}>
							<p>Text</p>
							<textarea
								value={data.sendNotificationTelegram.value || ''}
								onChange={(e) => handleChangeFrom('sendNotificationTelegram', 'value', e.target.value)}
							/>
							<FormControl sx={{paddingRight: data.sendNotification.type ? 3 : 1}}>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={data.sendNotificationTelegram.type}
									// @ts-ignore
									onChange={(e: ChangeEvent<HTMLInputElement>) => {
										handleChangeFrom('sendNotificationTelegram', 'type', e.target.value);
									}}
									sx={{color: '#D8D8D8'}}
								>
									<MenuItem value={0}>All</MenuItem>
									<MenuItem value={1}>User ID</MenuItem>
								</Select>
							</FormControl>
						</div>

						{ data.sendNotificationTelegram.type ?
							<div style={{marginTop: 10}}>
								<p style={{marginBottom: 5, color: '#B4B4B4', textAlign: 'left', width: '100%'}}>ID user</p>
								<div className={styles.body_amount}>
									<p>@</p>
									<input
										value={data.sendNotificationTelegram.userId || ''}
										onChange={(e) => handleChangeFrom('sendNotificationTelegram', 'userId', e.target.value)}
										type="text"
									/>
								</div>
							</div>
							: ''
						}
						<div style={{display: 'flex', flexDirection: 'column', alignItems: 'end'}}>
							<button onClick={handleSendTelegramNotification}
								style={{background: '#4C4AEF', padding: '8px 16px', color: '#EDEDFD', border: 'none', marginTop: 10, borderRadius: 5}}>
								<p>Send notification</p>
							</button>
						</div>

					</div>


				</div>

				<AdminElement
					all
					title="MAX energy"
					description=""
					btnText="Set MAX ENERGY"
					icon="⚡️"
					data={data}
					handleChangeFrom={handleChangeFrom}
					type="energy"
					select={false}
					handleSave={changeMaxEnergy}
				/>

			</div>

			<Modal
				open={modalUsers}
				onClose={() => setModalUsers(false)}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				style={{background: '#000'}}
			>
				<div ref={ref} onScroll={scroll} style={{...style, display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
					<div onClick={() => setModalUsers(false)}><CloseIcon /></div>
					{allUsers && (
						<>
							<div style={{display: 'flex', marginBottom: 20, marginTop: 10, gap: 10, width: '100%'}}>
								<input style={{outline: 'none', background: '#191B1E',
									padding: '12px', width: '100%', border: 'none', borderRadius: 5, color: '#fff'}}
								type="text" name="" id=""
								onChange={e => setSearchInp(e.target.value)}
								/>
								<button onClick={() => searchInp && dispatch(searchUsers({search: searchInp}))}
									style={{background: 'rgb(76, 74, 239)', color: '#fff', border: 'none', padding: '7px 20px', borderRadius: 5}}>Search</button>
							</div>
							<div style={{display: 'flex', flexDirection: 'column', gap: 20, width: '100%'}}>
								{
									allUsers?.results.map(el => (
										<div style={{border: '1px solid gray', padding: 10}}>
											<p onClick={() => copyText(el.id.toString())} style={{cursor: 'pointer'}}>ID: {el.id}</p>
											<p onClick={() => copyText(el.username)} style={{cursor: 'pointer'}}>Username: {el.username || '-'}</p>
											<p onClick={() => copyText(el.first_name)} style={{cursor: 'pointer'}}>First name: {el.first_name}</p>
											<p onClick={() => copyText(el.country)} style={{cursor: 'pointer'}}>Country: {el.country}</p>
											<p onClick={() => copyText(el.city)} style={{cursor: 'pointer'}}>City: {el.city}</p>
											<p onClick={() => copyText(el.crypto_bank.toString())}
												style={{cursor: 'pointer'}}>Crypto bank: {customToFixed(el.crypto_bank, 2)}</p>
											<p onClick={() => copyText(el.crypto_pump.toString())}
												style={{cursor: 'pointer'}}>Crypto pump: {customToFixed(el.crypto_pump, 2)}</p>
										</div>
									))
								}
							</div>
							{
								allUsers?.count > 1 ?
									<Pagination
										variant="outlined"
										shape="rounded"
										page={allUsers.current_page}
										boundaryCount={1}
										siblingCount={2}
										color="primary"
										count={allUsers.num_pages}
										renderItem={item => (
											<PaginationItem style={{color: '#fff'}} {...item} />
										)}
										onChange={(event: object, page: number) =>
											dispatch(getUsers({
												page,
											}))
										}
										style={{ marginTop: 20, width: '100%' }}
									/> : null
							}
						</>
					)}
				</div>
			</Modal>
		</div>
	);
};

export default Admin;
