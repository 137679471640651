import FiberManualRecordSharpIcon from '@mui/icons-material/FiberManualRecordSharp';
import FAQ from 'assets/image/FAQ.svg';
import FAQActive from 'assets/image/FAQActive.svg';
import Home from 'assets/image/Home.svg';
import HomeActive from 'assets/image/HomeActive.svg';
import Plus from 'assets/image/Plus.svg';
import Transaction from 'assets/image/Transaction.svg';
import TransactionActive from 'assets/image/TransactionActive.svg';
import Withdraw from 'assets/image/withdraw.svg';
import WithdrawActive from 'assets/image/withdrawActive.svg';
import { statEvent } from 'crud';
import { useAppDispatch } from 'hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { setTopUp, setWithdraw } from 'store/slices/Modal';
import { cls } from 'utils/cls';

import styles from './Footer.module.scss';

interface FooterProps {
	className?: string
}

const Footer = ({ className }: FooterProps) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const exceptionsFooter = ['cryptoGame', 'cryptoGameGuide', 'miningBot', 'calculator', 'convertCoins', 'start'];
	const location = useLocation();
	const checkoutActive = (path: string) => {
		return location.pathname.includes(path);
	};

	if (exceptionsFooter.some(path => location.pathname.includes(path))) return <></>;
	return (
		<div className={cls(styles.Footer, {}, [className || ''])}>
			<div onClick={() => {
				dispatch(statEvent('home'));
				navigate('/');
			}}>
				<img src={location.pathname === '/' ? HomeActive : Home} alt="" />
				<p style={{opacity: location.pathname === '/' ? 1 : 0.5, fontSize: 10}}>Home</p>
				{/* {location.pathname === '/' && <FiberManualRecordSharpIcon style={{color: '#4C4AEF', width: 6, height: 6}}/>} */}
			</div>
			<div onClick={() => {
				dispatch(statEvent('transactions'));
				navigate('/transactions');
			}}>
				<img src={checkoutActive('transactions') ? TransactionActive : Transaction} alt="" />
				<p style={{opacity: checkoutActive('transactions') ? 1 : 0.5, fontSize: 10}}>Transactions</p>
				{/* {checkoutActive('transactions') && <FiberManualRecordSharpIcon style={{color: '#4C4AEF', width: 6, height: 6}}/>} */}
			</div>
			<div onClick={() => {
				dispatch(statEvent('topup_quad'));
				dispatch(setTopUp(true));
			}} className={styles.topUp}>
				<img src={Plus} alt="" />
			</div>
			<div onClick={() => {
				dispatch(statEvent('withdraw'));
				dispatch(setWithdraw(true));
			}}>
				<img src={Withdraw} alt="" />
				<p style={{opacity: 0.5, fontSize: 10}}>Withdraw</p>
			</div>
			<div onClick={() => {
				dispatch(statEvent('faq'));
				window.open('https://telegra.ph/Crypto-Investor-Platform-FAQ-02-04');
			}}>
				<img src={checkoutActive('faq') ? FAQActive : FAQ} alt="" />
				<p style={{opacity: checkoutActive('faq') ? 1 : 0.5, fontSize: 10}}>FAQ</p>
				{/* {checkoutActive('faq') && <FiberManualRecordSharpIcon style={{color: '#4C4AEF', width: 6, height: 6}}/>} */}
			</div>
		</div>
	);
};

export default Footer;
