/* eslint-disable max-len */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { INotification } from 'interface/INotification';

export interface NotificationsState {
	notifications: INotification | null,
	isLoading: boolean,
	isViewed: number[],
	newNotifications: boolean
}

const initialState: NotificationsState = {
	notifications: null,
	isViewed: [],
	isLoading: false,
	newNotifications: false,
};

const notifications = createSlice({
	name: 'notifications',
	initialState,
	reducers: {
		setNotifications: (state, action: PayloadAction<INotification>) => {
			state.notifications = action.payload;
		},
		setIsViewed: (state, action: PayloadAction<number>) => {
			!state.isViewed.includes(action.payload) && state.isViewed.push(action.payload);
		},
		clearIsViewed: (state) => {
			state.isViewed = [];
		},
		setLoadingNotifications:(state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload;
		},
		setNewNotifications:(state, action: PayloadAction<boolean>) => {
			state.newNotifications = action.payload;
		},
		clearNotifications:(state) => {
			state.notifications = null;
		},
	},
});

// Action creators are generated for each case reducer function
export const { clearNotifications, setNotifications, setLoadingNotifications, setIsViewed, clearIsViewed, setNewNotifications} = notifications.actions;

export default notifications.reducer;