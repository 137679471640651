import * as React from 'react';

function Cirrcle() {
	return (
		<svg
			width={10}
			height={10}
			viewBox="0 0 12 12"
			style={{marginLeft: 5}}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx={6} cy={6} r={6} fill="#DF7B00" fillOpacity={1} />
		</svg>
	);
}

export default Cirrcle;
