type Mods = Record<string, boolean | string>

// eslint-disable-next-line @typescript-eslint/no-shadow
export function cls(cls: string, mods: Mods = {}, additional: string[] = []): string {
	return [
		cls,
		...additional.filter(Boolean),
		...Object.entries(mods)
			.filter(([_, value]) => Boolean(value))
			.map(([className]) => className),
	]
		.join(' ');
}