import { useDebounce } from '@uidotdev/usehooks';
import Header from 'components/Header/Header';
import { statEvent } from 'crud';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useEffect, useState } from 'react';
import { cls } from 'utils/cls';
import { customToFixed } from 'utils/customToFixed';
import { getUserParameters } from 'utils/getUserParameters';

import styles from './Calculator.module.scss';

interface CalculatorProps {
	className?: string;
}

const Calculator = ({ className }: CalculatorProps) => {
	const [inp, setInp] = useState('');
	const dispatch = useAppDispatch();
	const debouncedSearchTerm = useDebounce(inp, 800);
	const defaultInfo = useAppSelector((state) => state.info?.info);
	const user = useAppSelector((state) => state.auth?.user?.user);
	const [focus, setFocus] = useState(false);
	const [info, setInfo] = useState(defaultInfo);

	const countProfit = (days: number) => {
		let profit_to1 = (info?.cb_percent as number);
		let value = +inp;

		const finalAmount = value * (1 + profit_to1 * days);
		// if (finalAmount > (info?.cb_deposit_maximum || value)) {
		// 	return info?.cb_deposit_maximum;
		// }
		return customToFixed(finalAmount, 2);
	};

	useEffect(() => {
		if (user && defaultInfo) {
			setInfo(getUserParameters(defaultInfo, user));
		}
	}, [user, defaultInfo]);

	useEffect(() => {
		if (debouncedSearchTerm) {
			dispatch(statEvent(`calculateprofit_customamount{${inp}}`));
		}
	}, [debouncedSearchTerm]);

	if (!(user && defaultInfo)) return <></>;

	return (
		<div className={cls(styles.Calculator, {}, [className || ''])}>
			<Header title="CALCULATOR" />
			<div
				style={{
					padding: '30px 14px 25px 14px',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
					height: '100%',
					flex: 1,
				}}
			>
				<div>
					<p style={{ color: '#B4B4B4', fontSize: 14 }}>
            Enter the amount you want to calculate:
					</p>
					<div
						style={{ border: focus ? '1px solid rgb(76, 74, 239)' : '' }}
						className={styles.input}
					>
						<p>$</p>
						<input
							min="0"
							onChange={(e) => inp.length < 10 && setInp(e.target.value)}
							onKeyDown={(e) => {
								if (e.key === '-' || e.key === 'e') {
									e.preventDefault();
								}
							}}
							value={inp}
							onFocus={() => setFocus(true)}
							onBlur={() => setFocus(false)}
							type="number"
						/>
						<p>USD</p>
					</div>
					<div className={styles.calculate}>
						<div>
							<p>🚀Auto trading on PUMP🚀</p>
							<div>
								<p>Your deposit:</p>
								<p>${inp || '0'}</p>
							</div>
							<div>
								<p>Profit per 1 pump:</p>
								<p style={{ color: '#B4B4B4' }}>
									${customToFixed((+inp * (info?.cp_percent_minimum as number)), 2)}
									-
									{customToFixed((+inp * (info?.cp_percent_maximum as number)), 2)}</p>
							</div>
						</div>

						<div>
							<p>💰Crypto Bank💰</p>
							<div>
								<p>Your deposit:</p>
								<p>${inp || '0'}</p>
							</div>
							<div>
								<p>Profit per day</p>
								<p style={{ color: '#B4B4B4' }}>$ {countProfit(1)}</p>
							</div>
							<div>
								<p>Profit per week</p>
								<p style={{ color: '#B4B4B4' }}>$ {countProfit(6)}</p>
							</div>
							<div>
								<p>Profit per month</p>
								<p style={{ color: '#B4B4B4' }}>$ {countProfit(29)}</p>
							</div>
							<div style={{ border: 'none' }}>
								<p>Profit per year</p>
								<p style={{ color: '#B4B4B4' }}>$ {countProfit(364)}</p>
							</div>
						</div>
					</div>
				</div>
				{/* <button onClick={() => setCalculate(!calculate)} className={styles.btn}><p>{calculate ? 'Recalculate' : 'Calculate'}</p></button> */}
			</div>
		</div>
	);
};

export default Calculator;
