// TradingViewWidget.jsx
import React, { memo, useEffect, useRef } from 'react';

function TradingViewWidget() {
	const container = useRef<any>();

	useEffect(() => {
		const script = document.createElement('script');
		script.src =
      'https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js';
		script.type = 'text/javascript';
		script.async = true;
		script.innerHTML = `
        {
        "width": "100%",
        "height": "400",
        "symbol": "BINANCE:BNBUSDT",
        "interval": "5",
        "timezone": "Etc/UTC",
        "theme": "dark",
        "style": "1",
        "locale": "ru",
        "enable_publishing": false,
        "hide_top_toolbar": true,
        "save_image": false,
        "support_host": "https://www.tradingview.com"
        }`;
		container.current.appendChild(script);
	}, []);

	return (
		<div
			className="tradingview-widget-container"
			ref={container}
			style={{ height: '100%', width: '100%' }}
		>
			<div
				className="tradingview-widget-container__widget"
				style={{ height: 'calc(100% - 32px)', width: '100%' }}
			></div>
		</div>
	);
}

export default memo(TradingViewWidget);
