import line from 'assets/image/Line.svg';
import Header from 'components/Header/Header';
import { getPayouts } from 'crud';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useEffect } from 'react';
import { cls } from 'utils/cls';
import { customToFixed } from 'utils/customToFixed';

import styles from './Payouts.module.scss';

interface PayoutsProps {
	className?: string
}

const Payouts = ({ className }: PayoutsProps) => {
	const {payouts} = useAppSelector(state => state.payouts);
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(getPayouts());
	}, []);

	if (!payouts) return <></>;
	return (
		<div className={cls(styles.Payouts, {}, [className || ''])}>
			<Header title="Payouts"/>
			<div style={{padding: 16, width: '100%', display: 'flex', flexDirection: 'column', gap: 30}}>
				{
					payouts?.map(el => (
						<div className={styles.payoutEl}>
							<p>Payout #{el.id}</p>
							<img src={line} alt="" />
							<p className={styles.transactionDetails}>Transaction details</p>
							<div>
								<p>txHash</p>
								<p style={{cursor: 'pointer'}} onClick={() => window.open('https://tronscan.org/#/transaction/' + el.txhash)}>{el.txhash}</p>
							</div>
							<div>
								<div>
									<p>Time</p>
									<p>{el.time}</p>
								</div>
								<div>
									<p>Amount</p>
									<p>{customToFixed(el.amount, 2)} USDT</p>
								</div>
							</div>
						</div>
					))
				}
			</div>
		</div>
	);
};

export default Payouts;
