import { LinearProgress } from '@mui/material';
import { useDebounce } from '@uidotdev/usehooks';
import bi_coin from 'assets/image/bi_coin.svg';
import Coin from 'assets/image/coin.png';
import Header from 'components/Header/Header';
import { addClicksMine, addClicksMineExit, createTransaction, statEvent } from 'crud';
import { TransactionTypes } from 'enum';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { addEnergy, editClicks, setBotWorking } from 'store/slices/Auth';
import { setClickSum } from 'store/slices/ClicksMine';
import { setBuyMiningBot, setMethodPay2 } from 'store/slices/Modal';
import { cls } from 'utils/cls';
import { getUserParameters } from 'utils/getUserParameters';

import styles from './MiningBot.module.scss';

interface MiningBotProps {
	className?: string
}

interface ClickPosition {
	x: number;
	y: number;
}


const MiningBot = ({ className }: MiningBotProps) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [click, setClick] = useState(false);
	const coins = useAppSelector(state => state.auth.user?.user?.coins);
	const energy = useAppSelector(state => state.auth.user?.user?.energy);
	const info = useAppSelector(state => state.info.info);
	const clickSum = useAppSelector(state => state.clickMine.clickSum);

	const user = useAppSelector(state => state.auth.user?.user);
	const token = useAppSelector(state => state.auth.user?.token);
	const successClickData = useAppSelector(state => state.clickMine.success);

	const [isClicked, setIsClicked] = useState(false);
	const [clickPositions, setClickPositions] = useState<ClickPosition[]>([]);
	const debouncedSearchTerm = useDebounce(clickPositions, 300);

	function calculatePercentage(number: number, maxNumber: number) {
		return (number / maxNumber) * 100;
	}

	const handleClick = (event: React.MouseEvent<HTMLImageElement>) => {
		!click && setClick(true);
		setIsClicked(!isClicked);
		dispatch(setClickSum(clickSum + 1));
		(energy as number) > 0 && dispatch(editClicks({ energy: 1, coins: 1}));
		const { offsetX, offsetY } = event.nativeEvent;
		const newClickPosition = {
			x: offsetX,
			y: offsetY,
		};
		setClickPositions([...clickPositions, newClickPosition]);
	};

	useEffect(() => {
		if (click) {
			dispatch(statEvent('miningcoin_click1'));
		}
	}, [click]);

	useEffect(() => {
		if ((debouncedSearchTerm.length === clickPositions.length) && ((energy as number) > 0 && clickSum)) {
			dispatch(addClicksMine({clicks: clickSum, energy: clickSum}));
			dispatch(setClickSum(0));
		}
	}, [debouncedSearchTerm, clickSum]);

	const sendDataClick = () => {
		dispatch(addClicksMineExit());
		dispatch(setClickSum(0));
	};

	useEffect(() => {
		return () => {
			sendDataClick();
		};
	}, []);

	window.addEventListener('beforeunload', sendDataClick);

	useEffect(() => {
		if (user?.energy === info?.max_energy && user?.mining_bot) {
			dispatch(setBotWorking(true));
		}
		else if (user?.energy === 0 && user?.mining_bot?.is_working) {
			dispatch(setBotWorking(false));
		}
	}, [energy]);

	useEffect(() => {
		if (isClicked) {
			setTimeout(() => {
				setIsClicked(false);
			}, 100);
		}
	}, [isClicked]);

	useEffect(() => {
		setClickPositions([]);
	}, [debouncedSearchTerm]);

	useEffect(() => {
		if (token && user?.mining_bot?.is_working) {
		  const idInterval = setInterval(() => {
				if ((energy as number) > 0) {
			  dispatch(editClicks({ energy: 1, coins: 1 }));
				}
		  }, 1000);

		  return () => {
				clearInterval(idInterval);
		  };
		}
	  }, [user, energy, dispatch]);

	const botBtnStatus = () => {
		if (!user?.mining_bot && !user?.has_mb_open_request) {
			return 0;
			// '$55 mining bot';
		}
		else if (user?.has_mb_open_request) {
			return 1;
			// 'Pending';
		}
		else if (user?.mining_bot?.is_working) {
			return 2;
			// 'The bot\'s working';
		}
		else {
			return 3;
			// bot wait full energy
		}
	};

	const btnStatus = [
		{
			text: '$55 mining bot',
			color: '#4C4AEF',
			disable: false,
		},
		{
			text: 'Pending',
			color: '#272727',
			disable: true,
		},
		{
			text: 'The bot\'s working',
			color: '#0FA44D',
			disable: true,
		},
		{
			text: 'bot wait full energy',
			color: '#994614',
			disable: true,
		},
	][botBtnStatus()];

	return (
		<div className={cls(styles.MiningBot, {}, [className || ''])}>
			<Header title="Mining coin"/>
			<div className={styles.mining_body}>
				<div style={{display: 'flex', justifyContent: 'space-between'}}>
					<div>
						<p style={{color: 'rgb(25, 27, 30)', fontSize: 20, fontWeight: 700}}>Mining coin panel</p>
					</div>
					<img src={bi_coin} alt="" />
				</div>
				<div style={{display: 'flex', flexDirection: 'column', marginTop: 10, gap: 2}}>
					<div style={{display: 'flex', justifyContent: 'space-between'}}>
						<p style={{fontSize: 14, color: 'rgb(25, 27, 30)'}}>You are having</p>
						<p style={{fontSize: 16, fontWeight: 700, color: 'rgb(230, 104, 31)'}}>{coins || 0} coins</p>
					</div>
					<div style={{display: 'flex', justifyContent: 'space-between'}}>
						<p style={{fontSize: 14, color: 'rgb(25, 27, 30)'}}>Coin rate</p>
						<p style={{fontSize: 16, fontWeight: 700, color: 'rgb(230, 104, 31)'}}>1 coin = ${getUserParameters(info, user)?.coin_rate}</p>
					</div>
					<div style={{display: 'flex', justifyContent: 'space-between'}}>
						<p style={{fontSize: 14, color: 'rgb(25, 27, 30)'}}>Energy</p>
						<p style={{fontSize: 16, fontWeight: 700, color: 'rgb(230, 104, 31)'}}>{energy || 0}/{info?.max_energy || '-'} </p>
					</div>
					<LinearProgress sx={{backgroundColor: '#fff', height: 4, marginTop: .8}} variant="determinate"
						value={calculatePercentage(energy || 0, (info?.max_energy || 0))} />
				</div>
			</div>
			<div className={styles.mining_info}>
				<p>You can buy an auto click bot to automate coin collection. <br /> Maximum coins - 1.000.000</p>
			</div>
			<div>
				<div className={cls(styles.Action, {}, [className || ''])}>
					<div>
						<button onClick={() => {
							dispatch(statEvent('miningcoin_convertcoin'));
							navigate('/convertCoins');
						}}>
							<p>Convert coins</p>
						</button>
						<button
							onClick={() => {
								dispatch(setMethodPay2(true));
								dispatch(statEvent('miningcoin_buybot'));
							}}
						 style={{backgroundColor: btnStatus.color, opacity: 1}} disabled={btnStatus.disable}>
							<p>{btnStatus.text}</p>
						</button>
					</div>
				</div>
			</div>
			<div style={{width: '100%', display: 'flex', justifyContent: 'center', flex: 1, flexGrow: 1}}>
				<div style={{ position: 'relative', display: 'flex', width: 'max-content', paddingTop: 20}}>
					{Number(energy) > 0 && clickPositions.map((position, index) => (
						<p
							key={index}
							className={styles.addCoin}
							style={{
								position: 'absolute', top: position.y, left: position.x, color: '#fff',
								fontSize: 40, fontWeight: 'bold', zIndex: 300, transform: 'translate(-50%, -50%)',
								pointerEvents: 'none',
						 }}>1</p>
					))}
					<img
						onClick={handleClick}
						className={!isClicked ? styles.coin : `${styles.coin} ${styles.active}`}
						style={{ width: 300, height: 300 }} src={Coin} alt="" />
				</div>
			</div>
		</div>
	);
};

export default MiningBot;
