import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { Info } from 'interface/IInfo';
import { IStatisticsAdmin, IStatisticsPublic, ITopInvestors } from 'interface/IStatistics';

export interface AuthState {
	statisticsPublic: IStatisticsPublic | null,
	statisticsAdmin: IStatisticsAdmin | null
	isLoading: boolean,
	topInvestors: ITopInvestors | null,
}

const initialState: AuthState = {
	statisticsPublic: null,
	statisticsAdmin: null,
	topInvestors: null,
	isLoading: false,
};

const statistics = createSlice({
	name: 'statistics',
	initialState,
	reducers: {
		setStatisticsPublic: (state, action: PayloadAction<IStatisticsPublic>) => {
			state.statisticsPublic = action.payload;
		},
		setStatisticsAdmin: (state, action: PayloadAction<IStatisticsAdmin>) => {
			state.statisticsAdmin = action.payload;
		},
		setTopInvestors: (state, action: PayloadAction<ITopInvestors>) => {
			state.topInvestors = action.payload;
		},
		setLoadingStatistics: (state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { setTopInvestors, setStatisticsPublic, setLoadingStatistics, setStatisticsAdmin } = statistics.actions;

export default statistics.reducer;