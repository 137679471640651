import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { CurrencyTypes } from 'enum';
import { ITransactions } from 'interface/ITransactions';

export interface CreateTransaction {
	initiator?: number
	type?: number,
	bank?: number,
	amount?: number,
	currency?: number,
	payment_method?: number,
	status?: number,
	extra?: string | null
}

export interface AuthState {
	transactions: ITransactions | null,
	createTransaction: CreateTransaction| null,
	addressTransactions: {
		[key: string]: string
	}
	success: boolean,
	isLoading: boolean,
}

const initialState: AuthState = {
	transactions: null,
	success: false,
	isLoading: false,
	addressTransactions: {
		[CurrencyTypes.USDT]: 'TVsaKwhXnb6hywdGahNc5u1ywNw7PpNuwB',
		[CurrencyTypes.BNB]: '0xB81C6F849E1eA6F54cffA558f916D4463b15Be69',
		[CurrencyTypes.BTC]: '3ByxmrM1ev2Fko2gPbZSd2hbdm4B3xjiBp',
		[CurrencyTypes.DOGE]: 'DM2vhztn7NjADt56MUPKyLb7oxVekd4oHM',
		[CurrencyTypes.TRX]: 'TNf1xG6VUE7qEmbdio6qLvVhb89wuCE2e4',
		[CurrencyTypes.OTHER]: 'TVsaKwhXnb6hywdGahNc5u1ywNw7PpNuwB',
	},
	createTransaction: {
		type: 0,
		bank: 1,
		amount: 0,
		currency: 0,
		payment_method: 0,
		extra: null,
	},
};

const transactions = createSlice({
	name: 'transactions',
	initialState,
	reducers: {
		addTransactions:(state, action: PayloadAction<ITransactions>) => {
			state.transactions = action.payload;
		},
		editCreateTransactions:(state, action: PayloadAction<CreateTransaction>) => {
			state.createTransaction = {...state.createTransaction, ...action.payload};
		},
		clearCreateTransactions:(state) => {
			state.createTransaction = {
				type: 0,
				bank: 1,
				amount: 0,
				currency: 0,
				payment_method: 0,
				extra: null,
			};
		},
		setSuccess:(state, action: PayloadAction<boolean>) => {
			state.success = action.payload;
		},
		setLoadingTransactions:(state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { addTransactions, editCreateTransactions, setSuccess, setLoadingTransactions, clearCreateTransactions } = transactions.actions;

export default transactions.reducer;