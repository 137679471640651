import Ava from 'assets/image/Ava.svg';
import AvaFriend from 'assets/image/AvaFriend.svg';
import Header from 'components/Header/Header';
import { getFriends, statEvent } from 'crud';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useEffect, useState } from 'react';
import { cls } from 'utils/cls';
import { customToFixed } from 'utils/customToFixed';
import { getUserParameters } from 'utils/getUserParameters';

import styles from './Friends.module.scss';

interface FriendsProps {
	className?: string
}

const Friends = ({ className }: FriendsProps) => {
	const dispatch = useAppDispatch();
	const friends = useAppSelector(state => state.auth.friends);
	const user = useAppSelector(state => state.auth.user?.user);
	const info = useAppSelector(state => state.info.info);
	const [copied, setCopied] = useState(false);
	const userParameters = getUserParameters(info, user);

	useEffect(() => {
		dispatch(getFriends());
	}, []);

	const copyReferral = () => {
		if (user) {
			dispatch(statEvent('friends_copylink'));
			navigator.clipboard.writeText(`https://t.me/cryptoinvestorappBot/cryptoinvestor?startapp=${user.id}`).then(_ => {
				setCopied(true);
			});
		}
	};

	useEffect(() => {
		if (copied) {
			setTimeout(() => {
				setCopied(false);
			}, 2000);
		}
	}, [copied]);

	return (
		<div className={cls(styles.Friends, {}, [className || ''])}>
			<Header title="Friends"/>
			<div className={styles.friends_body}>
				<div style={{display: 'flex', justifyContent: 'space-between'}}>
					<div>
						<p style={{color: 'rgb(25, 27, 30)', fontSize: 20, fontWeight: 700}}>Friends</p>
						<p style={{color: 'rgb(54, 54, 54)', fontFamily: 'PoppinsRegular'}}>{user?.first_name || '-'}</p>
					</div>
					<img src={Ava} alt="" />
				</div>
				<div style={{display: 'flex', flexDirection: 'column', marginTop: 30, gap: 2}}>
					<div style={{display: 'flex', justifyContent: 'space-between'}}>
						<p style={{fontSize: 14, color: 'rgb(25, 27, 30)'}}>You are having:</p>
						<p style={{fontSize: 16, fontWeight: 700, color: 'rgb(76, 74, 239)'}}>{friends?.friends_number || '0'} friends</p>
					</div>
					<div style={{display: 'flex', justifyContent: 'space-between'}}>
						<p style={{fontSize: 14, color: 'rgb(25, 27, 30)'}}>Interest for friends investments:</p>
						<p style={{fontSize: 16, fontWeight: 700, color: 'rgb(76, 74, 239)'}}>{customToFixed((userParameters?.referral_interest as number * 100), 2)}%</p>
					</div>
					<div style={{display: 'flex', justifyContent: 'space-between'}}>
						<p style={{fontSize: 14, color: 'rgb(25, 27, 30)'}}>Your referral link:</p>
						<p onClick={copyReferral}
						 style={{fontSize: 16, cursor: 'pointer',
								fontWeight: 700, color: 'rgb(76, 74, 239)', borderBottom: '1px solid rgb(76, 74, 239)'}}>{!copied ? 'COPY LINK' : 'COPIED'}</p>
					</div>
					<div style={{display: 'flex', justifyContent: 'space-between', marginTop: 20}}>
						<p style={{fontSize: 14, color: 'rgb(25, 27, 30)', fontWeight: 700}}>Total earned:</p>
						<p style={{fontSize: 16, fontWeight: 700, color: 'rgb(76, 74, 239)'}}>{friends?.total_earned || '0'} USD</p>
					</div>
				</div>
			</div>
			<div className={styles.friends_info}>
				{/* eslint-disable-next-line max-len */}
				<p>The Friendly Program is designed to make an additional profit. Invite people by your referral link and get a percentage of all your friends deposits.</p>
			</div>
			<div className={styles.friends_list}>
				{
					friends?.transactions.map(transaction => (
						<div>
							<p>{transaction.date}</p>
							{transaction.transactions.map(el => (
								<div>
									<img src={AvaFriend} alt="" />
									<div>
										<p>{el.initiator.first_name}</p>
										<div>
											<p>invested</p>
											<p>You earn +{el.referal_profit} USD</p>
										</div>
									</div>
								</div>
							))}
						</div>
					))
				}
			</div>
		</div>
	);
};

export default Friends;
