import animationData from 'assets/lotties/animation.json';
import Lottie from 'react-lottie';
import { cls } from 'utils/cls';

import styles from './LoadingModal.module.scss';

interface LoadingModalProps {
	className?: string
}


const option = {
	loop: true,
	autoplay: true,
	animationData: animationData,
};


const LoadingModal = ({ className }: LoadingModalProps) => {
	return (
		<div className={cls(styles.LoadingModal, {}, [className || ''])}>
			<Lottie isClickToPauseDisabled height={400} options={option} />
			<p>Wait a few minutes...</p>
		</div>
	);
};

export default LoadingModal;
