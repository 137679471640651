import './Loading.css';

import animationData from 'assets/lotties/animation.json';
import { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import { cls } from 'utils/cls';

interface LoadingProps {
	className?: string
}

const ErrorCounter = ({ count }: any) => <span className="ErrorCounter">{count}</span>;

const option = {
	loop: true,
	autoplay: true,
	animationData: animationData,
};


const Loading = ({ className }: LoadingProps) => {
	return (
		<div className="loading_page">
			<Lottie isClickToPauseDisabled height={400} options={option} />
			<div className="pulse"></div>
			<div className="pulse2"></div>
			<h1 className="loading_page__h1" style={{textAlign: 'center'}}>CRYPTO<br />INVESTOR</h1>
		</div>
	);
};

export default Loading;
