import './App.css';

import LoadingModal from 'components/LoadingModal/LoadingModal';
import { ParticlesComp } from 'components/ParticlesComp/ParticlesComp';
import SnackbarComponent from 'components/SnackbarComponent/SnackbarComponent';
import { REACT_APP_API_DOMAIN } from 'config/api';
import { createOrGetUser, getInfo, getMe, getNotIsViewed, statEvent } from 'crud';
import {logEvent, setUserProperties} from 'firebase/analytics';
import { getAnalytics, setUserId } from 'firebase/analytics';
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { useAppDispatch, useAppSelector } from 'hooks';
import ErrorPageForPc from 'pages/ErrorPageForPc/ErrorPageForPc';
import Loading from 'pages/Loading/Loading';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { addEnergy } from 'store/slices/Auth';
import { getItem, getItemSession, setItem, setItemSession } from 'utils/localstorage';

import Navigation from './navigation/Navigation';
import { cls } from './utils/cls';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: 'AIzaSyDU3jO_kjW1hT_jwZs53I6CaJR3oLzjmlw',
	authDomain: 'myapp-a5018.firebaseapp.com',
	projectId: 'myapp-a5018',
	storageBucket: 'myapp-a5018.appspot.com',
	messagingSenderId: '896712650200',
	appId: '1:896712650200:web:b55d5f4097ed66b89f16ad',
	measurementId: 'G-VJ37CWGG0W',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const App = () => {
	const dispatch = useAppDispatch();
	const energy = useAppSelector((state) => state.auth.user?.user?.energy);
	const isLoadingTransaction = useAppSelector((state) => state.transactions.isLoading);
	const isLoadingGame = useAppSelector((state) => state.cryptoGame.isLoading);
	const isLoadingPayouts = useAppSelector((state) => state.payouts.isLoading);
	const isLoadingAuth = useAppSelector((state) => state.auth.isLoading);
	const isLoadingInfo = useAppSelector((state) => state.info.isLoading);
	const info = useAppSelector(state => state.info.info);
	const isLoadingAdmin = useAppSelector((state) => state.admin.isLoading);
	const isLoadingStatistics = useAppSelector((state) => state.statistics.isLoading);
	const user = useAppSelector((state) => state.auth);
	const error = useAppSelector((state) => state.auth.error);

	// @ts-ignore
	const WebApp = window?.Telegram?.WebApp;
	const BackButton = WebApp?.BackButton;
	const location = useLocation();
	const navigate = useNavigate();

	useLayoutEffect(() => {
		setItemSession('id', generateUniqueNumber().toString());
	}, []);

	useEffect(() => {
		const handleBeforeUnload = () => {
			const url = `${REACT_APP_API_DOMAIN}/stats/exitapp/`;
			const data = JSON.stringify({token: getItem('token'), session_id: getItemSession('id')});
			navigator.sendBeacon(url, data);
		};

		window.addEventListener('unload', handleBeforeUnload);

		return () => {
			window.removeEventListener('unload', handleBeforeUnload);
		};
	}, []);

	useEffect(() => {
		WebApp.ready();

		WebApp.onEvent('backButtonClicked', () => {
			if (location.pathname.includes('cryptoGame')) {
				dispatch(statEvent('back'));
				navigate('/');
				return;
			}
			dispatch(statEvent('back'));
			navigate(-1);
		});

		return () => {
			WebApp.offEvent('backButtonClicked');
			WebApp.offEvent('web_app_close');
			WebApp.offEvent('close');
		};
	  }, []);

	//   window.addEventListener('unload', function() {
	// 	const url = `${REACT_APP_API_DOMAIN}/stats/exitapp/`;
	// 	const data = JSON.stringify({token: getItem('token'), session_id: getItemSession('id')});
	// 	navigator.sendBeacon(url, data);
	// });

	useEffect(() => {
		if (location.pathname === '/' || location.pathname.includes('/tgWebAppData')) {
			BackButton?.hide();
		} else {
			BackButton?.show();
		}
	}, [WebApp, location]);

	const clearCache = () => {
		localStorage.clear();
		window.location.reload();
	};
	function generateUniqueNumber() {
		// Текущий временной штамп в миллисекундах
		const timestamp = Date.now();

		// Случайное число от 0 до 9999
		const randomNum = Math.floor(Math.random() * 10000);

		// Объединение временного штампа и случайного числа в одно уникальное число
		const uniqueNumber = parseInt(`${timestamp}${randomNum}`, 10);

		return uniqueNumber;
	  }

	useEffect(() => {
		// @ts-ignore
		const match = window?.Telegram?.WebApp?.initDataUnsafe.start_param;

		if (WebApp.initData) {
			dispatch(createOrGetUser({ initData: WebApp.initData, referral: match, session_id: getItemSession('id') || '' }));
			return;
		}
		// eslint-disable-next-line max-len
		// dispatch(createOrGetUser({ session_id: getItemSession('id') || '', initData: 'user=%7B%22id%22%3A1255278758%2C%22first_name%22%3A%22Kanat%20%DB%B5%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22kkanat07%22%2C%22language_code%22%3A%22ru%22%2C%22allows_write_to_pm%22%3Atrue%7D&chat_instance=-6882599953181823349&chat_type=private&auth_date=1719745285&hash=d2d6a6a15ad9cb470a2f935d1517e65fa13be17529c2b3aa9b9a8c92e0684976', referral: match }));

	}, [WebApp]);


	// useEffect(() => {
	// if (user.user?.token) {
	// 	dispatch(getInfo());
	// 	if (!getItem('is_reg')) {
	// 		logEvent(analytics, 'Registration', {
	// 			ID: user.user?.user.id,
	// 			FIRST_NAME: user.user?.user.first_name || '',
	// 			LASR_NAME: user.user?.user.last_name || '',
	// 			USER_NAME: user.user?.user.username || '',
	// 		  });
	// 		setItem('is_reg', 'true');
	// 	}
	// }
	// }, [user.user?.token]);

	useEffect(() => {
		if (!getItem('start')) {
			navigate('/');
		}
	}, []);

	useEffect(() => {
		if (user.user?.token && (energy || 0) < (info?.max_energy || 0)) {
			const intervalId = setInterval(() => {
				dispatch(addEnergy(1));
			}, 5000);

			return () => {
				clearInterval(intervalId);
			};
		}
	}, [user, energy, dispatch]);


	return (
		<div className={cls('App')}>
			{/* <button onClick={clearCache}>
				<p>Clear the cache</p>
			</button>
			<h1>{WebApp?.initData}</h1> */}
			<SnackbarComponent />
			{!WebApp?.initData ? <ErrorPageForPc /> : <Navigation />}
			{(isLoadingTransaction || isLoadingGame || isLoadingPayouts || isLoadingAuth || isLoadingInfo || isLoadingAdmin || isLoadingStatistics)
				&& (user?.user ? user.user?.user.checkbox ? <LoadingModal /> : <Loading /> : null)
			}
		</div>
	);
};

export default App;
