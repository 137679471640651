import Chart from 'assets/image/Chart.png';
import Header from 'components/Header/Header';
import { statEvent } from 'crud';
import { useAppDispatch } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { cls } from 'utils/cls';

import styles from './CryptoGameGuide.module.scss';

interface CryptoGameGuideProps {
	className?: string
}

const CryptoGameGuide = ({ className }: CryptoGameGuideProps) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	return (
		<div className={cls(styles.CryptoGameGuide, {}, [className || ''])}>
			<Header title={'Crypto Game Guide'}/>

			<div style={{margin: '0 20px'}}>
				<img style={{width: '100%', height: 310, position: 'relative'}} src={Chart} alt="" />
				<p style={{fontSize: 22, fontWeight: 700, textAlign: 'center', marginBottom: 15}}>How do you play CRYPTO GAME?</p>
				<div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
					<p className={styles.text}>
					1. Predict where the price BNB will go within 60 seconds.<br />
					2. Choose the size of your bank for the game bet.</p>

					<div className={styles.startGame__body}>
						<p>5%</p>
						<p>10%</p>
						<p>25%</p>
						<p>50%</p>
						<p>75%</p>
						<p>100%</p>
					</div>
					<p className={styles.text}>
					3. If you predict correctly, you will win and get +50% to “Your stake”<br />
					4. In case of losing, “Your stake” is burned.</p>
				</div>
				<button onClick={() => {
					dispatch(statEvent('cryptogame_info_okay'));
					navigate(-1);
				}} className={styles.btn}><p>Okay, time to start playing!</p></button>
			</div>
		</div>
	);
};

export default CryptoGameGuide;
