import { Info } from 'interface/IInfo';
import { User } from 'interface/IUser';

export const getUserParameters = (info: Info | null, user?: User) => {
	if (!(info && user)) return null;
	const info2 = structuredClone(info);

	Object.keys(info2)?.map(key => {
		// @ts-ignore
		let value = user[key];
		if (value) {
			// @ts-ignore
			info2[key] = value;
		}
	});
	return info2 || null;
};