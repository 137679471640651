import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { IClicksData } from 'interface/IClicksData';

export interface AuthState {
	clicksData: IClicksData | null,
	clickSum: number
	success: boolean
}

const initialState: AuthState = {
	clicksData: null,
	clickSum: 0,
	success: false,
};

const clicksMine = createSlice({
	name: 'clicksMine',
	initialState,
	reducers: {
		addClicks: (state, action: PayloadAction<IClicksData>) => {
			state.clicksData = action.payload;
		},
		setClickSum: (state, action: PayloadAction<number>) => {
			state.clickSum = action.payload;
		},
		setSuccessClick: (state, action: PayloadAction<boolean>) => {
			state.success = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { addClicks, setSuccessClick, setClickSum } = clicksMine.actions;

export default clicksMine.reducer;