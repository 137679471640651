import { Alert, Box, Modal, Snackbar } from '@mui/material';
import Info from 'assets/image/Info.svg';
import Lose from 'assets/image/Lose.svg';
import Shure from 'assets/image/Shure.svg';
import Win from 'assets/image/Win.svg';
import Header from 'components/Header/Header';
import TradingViewWidget from 'components/TradingViewWidget/TradingViewWidget';
import { createGame, getLastGame, getMe, statEvent } from 'crud';
import { GameDirection, GameResults } from 'enum';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { setLoadingGame, setPriceBNB } from 'store/slices/CryptoGame';
import { cls } from 'utils/cls';
import { customToFixed } from 'utils/customToFixed';
import { getTimestamp } from 'utils/getTimestamp';
import { getUserParameters } from 'utils/getUserParameters';
import { getItem, setItem } from 'utils/localstorage';

import styles from './CryptoGame.module.scss';

interface CryptoGameProps {
	className?: string
}

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '90%',
	padding: '24px 16px 16px 16px',
	justifyContent: 'center',
	display: 'flex',
	borderRadius: '12px',
};


const CryptoGame = ({ className }: CryptoGameProps) => {
	const [start, setStart] = useState(false);
	const [startGame, setStartGame] = useState(false);
	const [gameDirection, setGameDirection] = useState(0);
	const [time, setTime] = useState({
		days: '00',
		hours: '00',
		minutes: '00',
		seconds: '00',
	});
	const info = useAppSelector((state) => state.info?.info);
	const dispatch = useAppDispatch();
	const [error, setError] = useState(false);
	const [win, setWin] = useState(false);
	const [lose, setLose] = useState(false);
	const {priceBNB, lastGame, isLoading} = useAppSelector(state => state.cryptoGame);
	const user = useAppSelector(state => state.auth.user?.user);
	const pricesWs = new WebSocket('wss://stream.binance.com:9443/ws/bnbusdt@trade');
	const [percent, setPercent] = useState(0);
	const myBalance = (user?.crypto_bank || 0);

	const navigate = useNavigate();

	useEffect(() => {
		setInterval(() => {
			pricesWs.onmessage = function (msg) {
				dispatch(setPriceBNB(JSON.parse(msg.data).p));
				pricesWs.onmessage = null;
			};
		}, 2000);
	}, []);

	useEffect(() => {
		dispatch(getLastGame());
	}, []);

	const handleCreateGame = () => {
		dispatch(createGame({
			'percent': percent / 100,
			'direction': gameDirection,
		}));
	};

	useEffect(() => {
		if (lastGame?.result === GameResults.LOSE && !getItem('gameViewed')) {
			setLose(true);
			setItem('gameViewed', 'true');
		}
		else if (lastGame?.result === GameResults.WIN && !getItem('gameViewed')) {
			setWin(true);
			setItem('gameViewed', 'true');
		}
	}, [lastGame]);

	const convertMinutesToDDHHMM = (totalMinutes: number) => {
		const days = Math.floor(totalMinutes / (24 * 60));
		const hours = Math.floor((totalMinutes % (24 * 60)) / 60);
		const minutes = totalMinutes % 60;

		return `${String(days).padStart(2, '0')}:${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
	};


	useEffect(() => {
		if (lastGame?.result === GameResults.NOT_FINISHED) {
			dispatch(setLoadingGame(true));
			const interval = setInterval(() => {
				const endTimestamp = +getTimestamp(lastGame?.end_dt as string);
				let timer = endTimestamp - (Math.floor((new Date()).getTime() / 1000));

				if (timer >= 0) {
					dispatch(setLoadingGame(false));
					const days = Math.trunc(timer / (3600 * 24));
					timer -= days * 3600 * 24;
					const hours = Math.trunc(timer / 3600);
					timer -= hours * 3600;
					const minutes = Math.trunc(timer / 60);
					timer -= minutes * 60;
					const seconds = Math.trunc(timer);
					setTime({
						days: days.toString().padStart(2, '0'),
						hours: hours.toString().padStart(2, '0'),
						minutes: minutes.toString().padStart(2, '0'),
						seconds: seconds.toString().padStart(2, '0'),
					});
					setStartGame(true);
				} else if (lastGame.result === GameResults.NOT_FINISHED) {
					setTimeout(() => {
						dispatch(getLastGame());
					}, 1500);
					clearInterval(interval);
				}

			}, 1000);

			return () => clearInterval(interval);
		}
	}, [lastGame]);

	const colorPriceBNB = () => {
		if (lastGame && !lastGame.result) {
			if (lastGame?.direction === 1) {
				if (Number(priceBNB) > lastGame.current_price) {
					return '#26FF63';
				}
				else return '#D21C1C';
			}
			else if (lastGame?.direction === 2) {
				if (Number(priceBNB) < lastGame.current_price) {
					return '#26FF63';
				}
				else return '#D21C1C';
			}
		}
	};

	return (
		<>
			<Header title="Crypto Game"/>
			<div className={cls(styles.CryptoGame, {}, [className || ''])}>
				<div className={styles.balance}>
					<div>
						<p style={{color: '#B4B4B4', fontSize: 14, fontFamily: 'PoppinsSemiBold'}}>Cryptobank Balance</p>
						<p style={{color: '#fff', fontSize: 28, fontWeight: 700, marginTop: 5}}>${customToFixed(myBalance, 2) || 0}</p>
					</div>
					<img onClick={() => {
						dispatch(statEvent('cryptogame_info'));
						navigate('/cryptoGameGuide');
					}} src={Info} alt="" />
				</div>
				<TradingViewWidget />
				<div className={styles.gameStatus}>
					<p style={{fontSize: 20, fontWeight: 700}}>Game Status</p>
					<div className={styles.gameStatus__body}>
						<div>
							<p>Last Open</p>
							<p>{lastGame?.current_price || '-'}</p>
						</div>
						<div>
							<p>Price now</p>
							<p style={{color: colorPriceBNB()}}>
								{Number(priceBNB) || '-'}</p>
						</div>
						<div>
							<p>Your stake</p>
							<p>{(!lastGame?.is_viewed && customToFixed((lastGame?.amount || 0), 2)) || 0}</p>
						</div>
						<div>
							<p>Next round</p>
							<p>
								{convertMinutesToDDHHMM(Number(getUserParameters(info, user)?.crypto_game_duration))}</p>
						</div>
					</div>
				</div>

				{(!lastGame || lastGame.result !== GameResults.NOT_FINISHED) ?
					<><div className={styles.startGame}>
						<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
							<p style={{fontSize: 20, fontWeight: 700}}>Start Game</p>
							<p style={{fontSize: 16, color: '#B7B6F8'}}>${customToFixed(+((myBalance / 100) * percent), 2) || '0000.00'}</p>
						</div>
						<div className={styles.startGame__body}>
							<p style={{cursor: 'pointer', border: percent === 5 ? '1px solid gray' : ''}} onClick={() => {
								dispatch(statEvent('cryptogame_set%5'));
								setPercent(5);
							}}>5%</p>
							<p style={{cursor: 'pointer', border: percent === 10 ? '1px solid gray' : ''}} onClick={() => {
								dispatch(statEvent('cryptogame_set%10'));
								setPercent(10);
							}}>10%</p>
							<p style={{cursor: 'pointer', border: percent === 25 ? '1px solid gray' : ''}} onClick={() => {
								dispatch(statEvent('cryptogame_set%25'));
								setPercent(25);
							}}>25%</p>
							<p style={{cursor: 'pointer', border: percent === 50 ? '1px solid gray' : ''}} onClick={() => {
								dispatch(statEvent('cryptogame_set%50'));
								setPercent(50);
							}}>50%</p>
							<p style={{cursor: 'pointer', border: percent === 75 ? '1px solid gray' : ''}} onClick={() => {
								dispatch(statEvent('cryptogame_set%75'));
								setPercent(75);
							}}>75%</p>
							<p style={{cursor: 'pointer', border: percent === 100 ? '1px solid gray' : ''}} onClick={() => {
								dispatch(statEvent('cryptogame_set%100'));
								setPercent(100);
							}}>100%</p>
						</div>
					</div>
					<div className={styles.btn}>
						<button onClick={() => {
							if (user?.crypto_bank === 0) {
								setError(true);
								return;
							}
							if (!percent) {
								return;
							};
							dispatch(statEvent('cryptogame_up'));
							setStart(true);
							setGameDirection(GameDirection.UP);
						}}>UP</button>
						<button onClick={() => {
							if (user?.crypto_bank === 0) {
								setError(true);
								return;
							}
							if (!percent) {
								return;
							};
							dispatch(statEvent('cryptogame_down'));
							setStart(true);
							setGameDirection(GameDirection.DOWN);
						}}>DOWN</button>
					</div>
					</>
					:
					<div>
						<p style={{fontSize: 34, fontWeight: 700, textAlign: 'center', marginTop: 15, marginBottom: 10}}>GAME RESULTS</p>

						{time.days === '00' ?
							<div>
								<div style={{display: 'flex', justifyContent: 'center', gap: 20}}>
									<div>
										<p style={{fontSize: 34, fontWeight: 700, textAlign: 'center'}}>{time.minutes}</p>
										<p style={{fontSize: 12, fontWeight: 700, textAlign: 'center'}}>Minutes</p>
									</div>
									<div>
										<p style={{fontSize: 34, fontWeight: 700, textAlign: 'center'}}>{time.seconds}</p>
										<p style={{fontSize: 12, fontWeight: 700, textAlign: 'center'}}>Seconds</p>
									</div>
								</div>
							</div> :
							<div style={{display: 'flex', justifyContent: 'center', gap: 20}}>
								<div>
									<p style={{fontSize: 34, fontWeight: 700, textAlign: 'center'}}>{time.days}</p>
									<p style={{fontSize: 12, fontWeight: 700, textAlign: 'center'}}>Days</p>
								</div>
								<div>
									<p style={{fontSize: 34, fontWeight: 700, textAlign: 'center'}}>{time.hours}</p>
									<p style={{fontSize: 12, fontWeight: 700, textAlign: 'center'}}>Hours</p>
								</div>
								<div>
									<p style={{fontSize: 34, fontWeight: 700, textAlign: 'center'}}>{time.minutes}</p>
									<p style={{fontSize: 12, fontWeight: 700, textAlign: 'center'}}>Minutes</p>
								</div>
							</div>
						}
					</div>}
			</div>

			<Modal
				open={start}
				onClose={() => setStart(false)}
			>
				<Box sx={{...style, backgroundColor: '#191B1E', outline: 'none', flexDirection: 'column', alignItems: 'center'}}>
					<img src={Shure} alt="" />
					<div>
						<p style={{fontSize: 16, fontWeight: 600, marginTop: 50, textAlign: 'center'}}>Are you sure?</p>
						<p style={{fontSize: 14, color: '#B4B4B4', textAlign: 'center', marginTop: 10}}>After confirmation, the game will start.</p>
					</div>
					<div style={{width: '100%', marginTop: 50}}>
						<button onClick={() => {
							dispatch(statEvent('cryptogame_confirm_ok'));
							handleCreateGame();
							setStart(false);
							// setTimer(true);
							// setStart(false);
						}} className={styles.btnAgree}>OK</button>
						<p onClick={() => {
							dispatch(statEvent('cryptogame_confirm_cancel'));
							setStart(false);
						}} style={{color: '#4C4AEF', textAlign: 'center', marginTop: 8}}>Cancel</p>
					</div>
				</Box>
			</Modal>

			<Modal
				open={win}
				onClose={() => setWin(false)}
			>
				<Box sx={{...style, backgroundColor: '#191B1E', outline: 'none', flexDirection: 'column', alignItems: 'center'}}>
					<img src={Win} alt="" />
					<div>
						<p style={{fontSize: 16, fontWeight: 600, marginTop: 15, textAlign: 'center'}}>You Win!</p>
						<p style={{fontSize: 14, color: '#B4B4B4', textAlign: 'center', marginTop: 10}}>
							You won this round and your reward was
							+${customToFixed(((Number(lastGame?.amount) / 2) || 0), 2)} USDT! The reward has already been credited to your Cryptobank account.
						</p>
					</div>
					<div style={{width: '100%', marginTop: 15}}>
						<button onClick={() => {
							dispatch(statEvent('cryptogame_win_ok'));
							setWin(false);
						}} style={{height: 50}} className={styles.btnAgree}>OK</button>
					</div>
				</Box>
			</Modal>

			<Modal
				open={lose}
				onClose={() => setLose(false)}
			>
				<Box sx={{...style, backgroundColor: '#191B1E', outline: 'none', flexDirection: 'column', alignItems: 'center'}}>
					<img src={Lose} alt="" />
					<div>
						<p style={{fontSize: 16, fontWeight: 600, marginTop: 15, textAlign: 'center'}}>You Lose!</p>
						<p style={{fontSize: 14, color: '#B4B4B4', textAlign: 'center', marginTop: 10}}>
						You have lost this round and your bet ${customToFixed((lastGame?.amount || 0), 2)} has been burned! You can start a new game.
						</p>
					</div>
					<div style={{width: '100%', marginTop: 15}}>
						<button onClick={() => {
							dispatch(statEvent('cryptogame_loose_ok'));
							setLose(false);
						}} style={{height: 50}} className={styles.btnAgree}>OK</button>
					</div>
				</Box>
			</Modal>

			<Snackbar open={error} autoHideDuration={3000} onClose={() => setError(false)}>
				<Alert
					onClose={() => setError(false)}
					severity="error"
					variant="filled"
					sx={{ width: '100%', background: '#d32f2f !important' }}
				>
                Top up your balance to start playing!
				</Alert>
			</Snackbar>
		</>
	);
};

export default CryptoGame;
