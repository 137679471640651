import Header from 'components/Header/Header';
import { getInfo, getMe } from 'crud';
import { BankTypes } from 'enum';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useEffect, useState } from 'react';
import { setTopUp, setWithdraw } from 'store/slices/Modal';
import { editCreateTransactions } from 'store/slices/Transactions';
import { cls } from 'utils/cls';
import { customToFixed } from 'utils/customToFixed';
import { getTimestamp } from 'utils/getTimestamp';
import { getUserParameters } from 'utils/getUserParameters';

import Action from '../../components/Action/Action';
import styles from './CryptoBank.module.scss';

interface CryptoBankProps {
	className?: string
}

const CryptoBank = ({ className }: CryptoBankProps) => {
	const dispatch = useAppDispatch();
	const info = useAppSelector(state => state.info.info);
	const user = useAppSelector(state => state.auth.user?.user);
	const [time, setTime] = useState({
		hours: '00',
		minutes: '00',
		seconds: '00',
	});
	const userParameters = getUserParameters(info, user);
	const handleChangeTopUp = () => {
		dispatch(editCreateTransactions({bank: BankTypes.CRYPTOBANK}));
		dispatch(setTopUp(true));
	};

	const handleChangeWithdraw = () => {
		dispatch(editCreateTransactions({bank: BankTypes.CRYPTOBANK}));
		dispatch(setWithdraw(true));
	};

	useEffect(() => {
		if (info) {
			const interval = setInterval(() => {
				const endTimestamp = +getTimestamp(info?.cb_end_dt as string);
				let timer = endTimestamp - (Math.floor((new Date()).getTime() / 1000));

				if (timer === 0) {
					setTime({
						hours: '00',
						minutes: '00',
						seconds: '00',
					});
					setTimeout(() => {
						dispatch(getInfo());
						dispatch(getMe());
					}, 3000);
				}
				else if (timer >= 0) {
					const hours = Math.trunc(timer / 3600);
					timer -= hours * 3600;
					const minutes = Math.trunc(timer / 60);
					timer -= minutes * 60;
					const seconds = Math.trunc(timer);
					setTime({
						hours: hours.toString().padStart(2, '0'),
						minutes: minutes.toString().padStart(2, '0'),
						seconds: seconds.toString().padStart(2, '0'),
					});
				}
			}, 1000);

			return () => clearInterval(interval);
		}
	  }, [info]);

	if (!info || !user) return <></>;

	return (
		<div className={cls(styles.CryptoBank, {}, [className || ''])}>
			<Header title="Crypto bank"/>
			<div className={styles.balance}>
				<div style={{marginBottom: 20}}>
					<p style={{color: '#363636', fontSize: 14, fontWeight: 700}}>Cryptobank balance</p>
					<p style={{color: '#0C0C0C', fontSize: 18}}>${customToFixed(user?.crypto_bank, 2) || 0}</p>
				</div>
				<div>
					<p style={{color: '#363636', fontSize: 14}}>Percentage of deposit:</p>
					<p style={{color: '#0C0C0C', fontSize: 18}}>{customToFixed((userParameters?.cb_percent || 0) * 100, 2) || '-'}%</p>
				</div>
				<div>
					<p style={{color: '#363636', fontSize: 14}}>Yield time:</p>
					<p style={{color: '#0C0C0C', fontSize: 18}}>{info?.cb_fundrase_duration || '-'} hours</p>
				</div>
				<div>
					<p style={{color: '#363636', fontSize: 14}}>Deposit term:</p>
					<p style={{color: '#0C0C0C', fontSize: 18}}>Termless</p>
				</div>
				<div style={{marginTop: 20}}>
					<p style={{color: '#363636', fontSize: 14}}>Time to fundraise:</p>
					<p style={{color: '#0C0C0C', fontSize: 18}}>{`${time.hours}:${time.minutes}:${time.seconds}`}</p>
				</div>
			</div>
			<Action type={'cryptobank'} handleChangeTopUp={handleChangeTopUp} handleChangeWithdraw={handleChangeWithdraw} />
			<div className={styles.whatIs}>
				<p>What is Cryptobank?</p>
				<p>
                    Keep your cryptocurrency safe and make it work for you.
                    In a crypto bank, you will receive {customToFixed((userParameters?.cb_percent || 0) * 100, 2) || '-'}% per day for keeping your deposit.
                    These are very favorable conditions for obtaining passive income.</p>
			</div>
		</div>
	);
};

export default CryptoBank;
