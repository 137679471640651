import DeleteIcon from '@mui/icons-material/Delete';
import { deleteNotification, statEvent } from 'crud';
import { useAppDispatch } from 'hooks';

import { cls } from '../../utils/cls';
import styles from './Header.module.scss';

interface HeaderProps {
	className?: string,
	title: string,
	notification?: boolean
}

const Header = ({ className, title, notification }: HeaderProps) => {
	const dispatch = useAppDispatch();

	const handleDeleteNotification = () => {
		dispatch(deleteNotification());
	};

	return (
		<div className={cls(styles.Header, {}, [className || ''])}>
			<p>{title}</p>
			{notification && <div onClick={handleDeleteNotification} style={{position: 'absolute', right: 12}}>
				<div onClick={() => {
					dispatch(statEvent('notifications_clear'));
				}}><DeleteIcon /></div>
			</div>}
		</div>
	);
};

export default Header;
