
import 'swiper/css/pagination';

import InfoIcon from '@mui/icons-material/Info';
import { Checkbox } from '@mui/material';
import Start1 from 'assets/image/Start1.svg';
import Start2 from 'assets/image/Start2.svg';
import Start3 from 'assets/image/Start3.svg';
import { statEvent } from 'crud';
import { useAppDispatch } from 'hooks';
import Container from 'layouts/Container/Container';
import { useState } from 'react';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { cls } from 'utils/cls';
import { getItemSession } from 'utils/localstorage';

import NextButton from './NextButton/NextButton';
import styles from './Start.module.scss';

interface StartProps {
	className?: string
}

const Start = ({ className }: StartProps) => {
	const [skip, setSkip] = useState(false);
	const [agree, setAgree] = useState(false);
	const [next, setNext] = useState(false);
	const dispatch = useAppDispatch();

	return (
		<Container>
			<div className={cls(styles.Start, {}, [className || ''])}>
				<div className={cls(styles.skip)}>
					<button style={{cursor: 'pointer', background: 'transperent'}} onClick={() => {
						dispatch(statEvent('skip'));
						setSkip(true);
					}}>Skip</button>
				</div>
			</div>
			<Swiper
				modules={[Pagination]}
				style={{height: 'calc(100vh - 44px)'}}
				pagination={{el: `.${cls(styles.pagination)}`, clickable: true}}
				slidesPerView={1}
				spaceBetween={100}
			>
				<SwiperSlide>
					<div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', height: '100%'}}>
						<img className={styles.img} src={Start1} alt="" />
						<p className={cls(styles.text)}>Simple way to manage your investment</p>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', height: '100%'}}>
						<img className={styles.img2} src={Start2} alt="" />
						<p className={cls(styles.text)}>Simple invest in cryptobank, cryptoPUMP and accumulate</p>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'column', height: 'calc(100% - 170px)'}}>
						<div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', height: '100%'}}>
							<img className={styles.img3} src={Start3} alt="" />
							<p className={cls(styles.text)}>Deposit and withdraw easy and fast</p>
						</div>
						<div>
							<div onClick={() => {
								if (agree) {
									dispatch(statEvent('checkbox_off'));
								}
								else {
									dispatch(statEvent('checkbox_on'));
								}
								setAgree(!agree);
							}} style={{display: 'flex', alignItems: 'flex-start'}}>
								<Checkbox style={{color: '#363636', borderRadius: 10}} checked={agree} />
								<p>I have read and agree to the <span onClick={() => window.open('https://telegra.ph/Crypto-Investor-Policy-05-03')}
									style={{color: '#4443D7', cursor: 'pointer'}}>Crypto Investor Policy</span></p>
							</div>
							<div style={{display: 'flex', gap: 5, alignItems: 'center', justifyContent: 'center', marginTop: 10}}>
								<InfoIcon style={{color: !next || agree || agree ? '#4C4AEF' : '#D21C1C', width: 16, height: 16}} />
								<p style={{fontSize: 16, color: !next || agree ? '#4C4AEF' : '#D21C1C'}}>{!next || agree ?
									'To use the app, accept the agreement'
									: 'Read the agreement and check the box next to it'}</p>
							</div>
						</div>
					</div>
				</SwiperSlide>
				<div style={{position: 'absolute', bottom: 20, width: '100%', zIndex: 100, height: 140}}>
					<div className={cls(styles.pagination)}></div>
					<NextButton skip={skip} setSkip={setSkip} next={next} setNext={setNext} agree={agree}/>
				</div>
			</Swiper>
		</Container>
	);
};

export default Start;
