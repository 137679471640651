import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { ICryptoGame } from 'interface/ICryptoGame';

export interface AuthState {
	priceBNB: number | null
	lastGame: ICryptoGame | null
	isLoading: boolean
}

const initialState: AuthState = {
	priceBNB: null,
	lastGame: null,
	isLoading: false,
};

const cryptoGame = createSlice({
	name: 'cryptoGame',
	initialState,
	reducers: {
		setPriceBNB: (state, action: PayloadAction<number>) => {
			state.priceBNB = +action.payload;
		},
		setLastGame: (state, action: PayloadAction<ICryptoGame>) => {
			state.lastGame = action.payload;
		},
		setLoadingGame: (state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { setPriceBNB, setLastGame, setLoadingGame } = cryptoGame.actions;

export default cryptoGame.reducer;
