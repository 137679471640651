import { Box, Modal } from '@mui/material';
import Admin from 'assets/image/admin.svg';
import CryptoBank from 'assets/image/CryptoBank.svg';
import CryptoFriends from 'assets/image/CryptoFriends.svg';
import CryptoGame from 'assets/image/CryptoGame.svg';
import CryptoMining from 'assets/image/CryptoMining.svg';
import CryptoPUMP from 'assets/image/CryptoPUMP.svg';
import CryptoSupport from 'assets/image/CryptoSupport.svg';
import Notification from 'assets/image/Notification.svg';
import Notification2 from 'assets/image/Notification2.svg';
import Plus from 'assets/image/Plus.svg';
import Settings from 'assets/image/Settings.svg';
import Telegram from 'assets/image/telegram.svg';
import animationData from 'assets/lotties/animation.json';
import { ParticlesComp } from 'components/ParticlesComp/ParticlesComp';
import { getInfo, getMe, getNotIsViewed, statEvent } from 'crud';
import { useAppDispatch, useAppSelector } from 'hooks';
import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import { useNavigate } from 'react-router-dom';
import { setTopUp } from 'store/slices/Modal';
import { customToFixed } from 'utils/customToFixed';
import { getItem } from 'utils/localstorage';

import { cls } from '../../utils/cls';
import styles from './Home.module.scss';

interface HomeProps {
	className?: string
}

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '90%',
	bgcolor: '#191B1E',
	boxShadow: 24,
	outline: 'none',
	p: 1,
	borderRadius: 5,
};

const option = {
	loop: true,
	autoplay: true,
	animationData: animationData,
};

const Home = ({ className }: HomeProps) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const {user} = useAppSelector(state => state.auth);
	const {newNotifications} = useAppSelector(state => state.notification);
	const [reject, setReject] = useState(false);
	const {info} = useAppSelector(state => state.info);

	useEffect(() => {
		if (user?.token && getItem('token')) {
			dispatch(getMe());
			dispatch(getInfo());
			dispatch(getNotIsViewed());
		}
	}, [user?.token]);

	useEffect(() => {
		if (!user?.user?.groups?.includes('admins') && (getItem('country') === 'RU' || getItem('country') === 'BY')) {
			setTimeout(() => {
				setReject(true);
			}, 1000);
		}
	}, [user]);

	const textLineBreak = (text: string) => {;
		const newText = text.split('\n').map((line, index) => (
		  <>
				{line}
				<br />
		  </>
		));
		return newText;
	};


	return (
		<div className={cls(styles.Home, {}, [className || ''])}>
			{/* <h1>country: {country}</h1> */}
			<div className={cls(styles.header)}>
				<ParticlesComp />
				<div className={cls(styles.headerInfo)}>
					<div>
						<p style={{color: '#fff', fontSize: 16, textShadow: '2px 2px 2px #000'}}>Welcome back!</p>
						<p style={{color: '#fff', textShadow: '2px 2px 2px #000'}}
						>{user?.user?.username ? `@${user?.user?.username}` : user?.user?.first_name || '-'}</p>
					</div>
					<div style={{gap: 20, display: 'flex'}}>
						{user?.user.groups.includes('admins') && <img style={{width: 28}} onClick={() => navigate('/admin')} src={Admin} alt="" />}
						<img style={{width: 35}} onClick={() => {
							dispatch(statEvent('community'));
							window.open('https://t.me/+F6wftkVFeOc4ZmUy');
						}} src={Telegram} alt="" />
						<img style={{width: 28}} onClick={() => {
							dispatch(statEvent('settings'));
							navigate('/setting');
						}} src={Settings} alt="" />
						<img style={{width: 28}} onClick={() => {
							dispatch(statEvent('notification'));
							navigate('/notification');
						}} src={newNotifications ? Notification : Notification2} alt="" />
					</div>
				</div>
				<div className={cls(styles.balance)}>
					<div>
						<p style={{fontFamily: 'PoppinsSemiBold', color: '#C8C7FA', fontSize: 14}}>Total Balance</p>
						<p style={{fontFamily: 'PoppinsSemiBold', fontSize: 30}}>${customToFixed((user?.user?.crypto_bank || 0) + (user?.user?.crypto_pump || 0), 2)}</p>
					</div>
					<div
						onClick={() => {
							dispatch(statEvent('topup_circle'));
							dispatch(setTopUp(true));
						}}
						style={{
							width: 50, height: 50, borderRadius: '100%', backgroundColor: '#4C4AEF',
							display: 'flex', alignItems: 'center', justifyContent: 'center', boxShadow: 'rgb(76, 74, 239) 0px 0px 20px 5px',
						}}>
						<img style={{width: '35%'}} src={Plus} alt="" />
					</div>
				</div>
			</div>
			<div className={styles.product}>
				<div onClick={() => {
					dispatch(statEvent('cryptobank'));
					navigate('/cryptobank');
				}}
				 style={{ background: 'linear-gradient(96.53deg, #FF9557 6.95%, #FDD0B6 107.8%)'}} className={styles.element}>
					<img src={CryptoBank} alt="" />
					<p>Cryptobank</p>
				</div>
				<div onClick={() => {
					dispatch(statEvent('cryptopump'));
					navigate('/cryptoPUMP');
				}}
				 style={{background: 'linear-gradient(134.32deg, #FEC6A5 25.22%, #FFE7D9 71.56%)'}} className={styles.element}>
					<img src={CryptoPUMP} alt="" />
					<p>CryptoPUMP</p>
				</div>
				<div onClick={() => {
					dispatch(statEvent('cryptogame'));
					navigate('/cryptoGame');
				}}
				 style={{ background: 'linear-gradient(-96.53deg, #FF9557 6.95%, #FDD0B6 107.8%)'}} className={styles.element}>
					<img src={CryptoGame} alt="" />
					<p>Crypto Game</p>
				</div>
				<div onClick={() => {
					dispatch(statEvent('friends'));
					navigate('/friends');
				}}
				 style={{ background: 'linear-gradient(96.53deg, #FF9557 6.95%, #FDD0B6 107.8%)'}} className={styles.element}>
					<img src={CryptoFriends} alt="" />
					<p>Friends</p>
				</div>
				<div onClick={() => {
					dispatch(statEvent('miningcoin'));
					navigate('/miningBot');
				}}
				 style={{background: 'linear-gradient(134.32deg, #FEC6A5 25.22%, #FFE7D9 71.56%)'}} className={styles.element}>
					<img src={CryptoMining} alt="" />
					<p>Mining Coin</p>
				</div>
				<div
					onClick={() => {
						dispatch(statEvent('support'));
						window.open('https://t.me/AngelaKwang');
					}}
					style={{ background: 'linear-gradient(-96.53deg, #FF9557 6.95%, #FDD0B6 107.8%)'}} className={styles.element}>
					<img src={CryptoSupport} alt="" />
					<p>Support</p>
				</div>
			</div>
			<div className={styles.news}>
				<p>News</p>
				<div>
					<p className={styles.news_header}>
						{info?.news_header || '-'}
					</p>
					<p className={styles.news_body}>
						{textLineBreak(info?.news_description|| '-')}
					</p>
				</div>
			</div>
			<Modal
				open={reject}
				onClose={() => setReject(false)}
				sx={{background: 'rgba(0,0,0,0.5)'}}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<Lottie isClickToPauseDisabled height={250} options={option} />
					<p style={{textAlign: 'center', fontSize: 20}}>Not available for your location</p>
					<button style={{
						width: '100%',
						padding: 13,
						borderRadius: 12,
						marginTop: 20,
						backgroundColor: '#4C4AEF',
						color: '#fff',
						border: 'none',
					}}
					onClick={() => setReject(false)}
					><p>Close</p></button>
				</Box>
			</Modal>
		</div>
	);
};

export default Home;
