import { Alert, Snackbar } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks';
import { setError } from 'store/slices/Admin';

interface SnackbarProps {
	className?: string;
}

const SnackbarComponent = ({ className }: SnackbarProps) => {
	const error = useAppSelector(state => state.admin.error);
	const dispatch = useAppDispatch();
	return (
		<>
			<Snackbar open={error} autoHideDuration={3000} onClose={() => dispatch(setError(false))}>
				<Alert
					onClose={() => dispatch(setError(false))}
					severity="error"
					variant="filled"
					sx={{ width: '100%', background: '#d32f2f !important' }}
				>
                Error
				</Alert>
			</Snackbar>
		</>
	);
};

export default SnackbarComponent;