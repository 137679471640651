import { combineReducers, configureStore } from '@reduxjs/toolkit';
// Or from '@reduxjs/toolkit/query/react'
import { setupListeners } from '@reduxjs/toolkit/query';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import admin from './slices/Admin';
import authReducer from './slices/Auth';
import clicksMineReducer from './slices/ClicksMine';
import cryptoGame from './slices/CryptoGame';
import info from './slices/Info';
import modalReducer from './slices/Modal';
import notification from './slices/Notifications';
import payouts from './slices/Payouts';
import statistics from './slices/Statistics';
import transactions from './slices/Transactions';

const rootReducer = combineReducers({
	auth: authReducer,
	modal: modalReducer,
	clickMine: clicksMineReducer,
	transactions,
	info,
	notification,
	cryptoGame,
	payouts,
	statistics,
	admin,
});

const persistConfig = {
	key: 'root',
	storage,
	blacklist: ['auth', 'clickMine', 'modal', 'transactions', 'info', 'notification', 'cryptoGame', 'payouts', 'statistics', 'admin'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
});

export const persistor = persistStore(store);

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch