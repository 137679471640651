import { FormControl, MenuItem, Select } from '@mui/material';
import { BankTypes } from 'enum';
import { useEffect } from 'react';

import styles from '../Admin.module.scss';

interface AdminElementProps {
	title: string
	icon: string
	btnText: string
	type: string
	description: string
	data: any
	handleChangeFrom: any
	friends?: boolean
	all?: boolean
	select?: boolean
	handleSave?: any
}

const AdminElement = ({ title, data, handleChangeFrom, btnText, icon, description, type, friends, all, select = true, handleSave }: AdminElementProps) => {
	return (
		<div>
			<p style={{marginBottom: 5, color: '#B4B4B4'}}>{title}</p>
			<div className={styles.body_amount}>
				<p>{icon}</p>
				<input
					value={data[type].value || ''}
					onKeyDown={(event) => {
						// Разрешаем только цифры и только один знак "-"
						if (
							event.key !== 'Backspace' &&
							(event.key !== '-') &&
							(event.key !== '.') &&
							!/^\d$/.test(event.key)
						) {
							event.preventDefault();
						}
					}}
					onChange={(e) => handleChangeFrom(type, 'value', e.target.value)}
					type={'text'}
				/>
				{select && <FormControl sx={{paddingRight: 4}}>
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						value={data[type].bank}
						// @ts-ignore
						onChange={(e: ChangeEvent<HTMLInputElement>) => {
							handleChangeFrom(type, 'bank', e.target.value);
						}}
						sx={{color: '#D8D8D8'}}
					>
						<MenuItem value={BankTypes.CRYPTOBANK}>cryptobank</MenuItem>
						<MenuItem value={BankTypes.CRYPTOPUMP}>cryptoPUMP</MenuItem>
						{friends && <MenuItem value={3}>friends</MenuItem>}
					</Select>
				</FormControl>}
			</div>
			<p style={{color: '#B4B4B4', fontSize: 14}}>{description}</p>

			{!all && <div style={{marginTop: 20}}>
				<p style={{marginBottom: 5, color: '#B4B4B4', textAlign: 'left', width: '100%'}}>ID user</p>
				<div className={styles.body_amount}>
					<p>@</p>
					<input
						value={data[type].userId || ''}
						onChange={(e) => handleChangeFrom(type, 'userId', e.target.value)}
						type="text"
					/>
				</div>
			</div>}
			<div onClick={() => handleSave && handleSave()} style={{display: 'flex', flexDirection: 'column', alignItems: 'end'}}>
				<button style={{background: '#4C4AEF', padding: '8px 16px', color: '#EDEDFD', border: 'none', marginTop: 10, borderRadius: 5}}>
					<p>{btnText}</p>
				</button>
			</div>
		</div>
	);
};

export default AdminElement;
