import { BankTypes } from 'enum';
import { User } from 'interface/IUser';
import { CreateTransaction } from 'store/slices/Transactions';

export const getBalance = (createTransaction: CreateTransaction | null, user: User | undefined) => {
	if (createTransaction && user) {
		if (createTransaction.bank === BankTypes.CRYPTOBANK) {
			return user.crypto_bank;
		}
		else if(createTransaction.bank === BankTypes.CRYPTOPUMP) {
			return user.crypto_pump;
		}
	}
	return 0;
};