import Lock from 'assets/image/Lock.svg';
import { readRules, statEvent } from 'crud';
import { useAppDispatch } from 'hooks';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSwiper } from 'swiper/react';
import { cls } from 'utils/cls';
import { getItemSession, setItem } from 'utils/localstorage';

import styles from './NextButton.module.scss';

interface NextButtonProps {
	className?: string
	skip: boolean
	setSkip(skip: boolean): void
	next: boolean
	setNext(skip: boolean): void
	agree: boolean
}

const NextButton = ({ className, skip, setSkip, next, setNext, agree}: NextButtonProps) => {
	const swiper = useSwiper();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	useEffect(() => {
		if (skip) {
			swiper.slideTo(3);
			setSkip(false);
		}
	}, [skip]);
	return (
		<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
			<button onClick={() => {
				dispatch(statEvent(`next${swiper.activeIndex + 1}`));
				if (swiper.activeIndex !== 2) {
					swiper.slideNext();
				}
				else if (!agree) {
					setNext(true);
				}
				else {
					dispatch(readRules()).then(() => {
						window.location.reload();
					});
				}
			}} className={cls(styles.button)}>Next</button>
			<div style={{display: 'flex', gap: 7, color: '#B4B4B4', marginTop: 20}}>
				<img src={Lock} alt="" />
				<p>Your information is always sercurity</p>
			</div>
		</div>
	);
};

export default NextButton;
