import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { Info } from 'interface/IInfo';

export interface AuthState {
	info: Info | null,
	isLoading: boolean,
}

const initialState: AuthState = {
	info: null,
	isLoading: false,
};

const info = createSlice({
	name: 'info',
	initialState,
	reducers: {
		setInfo: (state, action: PayloadAction<Info>) => {
			state.info = action.payload;
		},
		setLoadingInfo:(state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { setInfo, setLoadingInfo} = info.actions;

export default info.reducer;